

const routerNames = {
    Login: 'login',
    NewPassword: 'newPassword',
    SendCode: 'sendCode',
    ChangePassword: '/sendCode/changePassword',
    ChangeOK: '/changeOk',
    DashBoard: '/home/dashBoard',
    VisitRecordInput: '/home/visitRecordInput',
    VisitRecordConfirm: '/home/visitRecordConfirm',
    MonthlyVisitingRecord: '/home/monthlyVisitingRecord',
    MonthlyVisitingRecordDetail: '/home/monthlyVisitingRecordDetail',
    MonthlyVisitingRecordConfirmDetail: '/home/monthlyVisitingRecordConfirmDetail',
    MonthlyRecordConfirmSubmitted: '/home/monthlyRecordConfirmSubmitted',
    VisitSubmitted: '/home/visitSubmitted',
    SubmitConfirmCSVOutput: '/home/submitConfirmCSVOutput',
    TerminalManage: '/home/terminalManage',
    TerminalStateInquiry: '/home/terminalStateInquiry',
    TerminalDetail: '/home/terminalDetail',
    SupportContents: '/home/supportContents'
}


const localstorage = {
    access_token: 'access_token',
    refresh_token: 'refresh_token',
    id_token: 'id_token',
    authority_id1: 'authority_id1',
    authority_id2: 'authority_id2',
    authority_id3: 'authority_id3',
    authority_id4: 'authority_id4',
    authority_id5: 'authority_id5',
    preferred_username: 'preferred_username',
    email: 'email',
    username: 'username',
    visitData: "visitData",
    masterData: "masterData",
    businessDay: "businessDay",
    menuItem: "menuItem",
    loginDate: "loginDate",
    helpFile: "helpFile"
}

const successMessage = {
    codeSendSuccess: '認証コードの送信に成功しました。'
}

const errorMessage = {
    namePasswordFormatError: 'メールアドレスもしくはパスワードが異なります',
    nameFormatError: 'メールアドレスを正しく入力してください。',
    sendCodeError: "メールの送信に失敗しました。",
    codeEmpty: "認証コードを入力してください。",
    codeFormat: "認証コードを正しく入力してください。",
    nameEmptyError: 'メールアドレスを入力してください。',
    passwordFormatError: 'パスワードは8文字以上、英大文字、英小文字、数字、記号をそれぞれ最低1つずつ含める必要があります。',
    passwordelseError: "パスワードには半角英数字、半角記号のみ含めることができます。",
    passwordNull: 'パスワードを入力してください。',
    newPassEmpty: "新しいパスワードを入力してください。",
    newConfirmPassEmpty: "新しいパスワード（確認用）を入力してください。",
    namePasswordDif: '新しいパスワード（確認用）が新しいパスワードと一致しません。',
    // emailFormatError: 'メールアドレスを正しく入力してください。',
    // zipCodeFormatError: '郵便番号は、000-0000の形式で入力してください。',
    // phoneFormatError: '電話番号は、0から9の半角数字、半角ハイフン(-)を利用して入力してください。',
    E0003: 'メールアドレスまたはパスワードに誤りがあります。',
    E0004: '日報データまたは訪問実績チェックボックスを選択してください。',
    E0005: '全端末データまたは預かり端末データまたは故障端末データチェックボックスを選択してください。',
    E00002: '認証コードが一致しません。',
    E00003: '認証コードの有効期限が切れています。再発行してください。',
    E00004: '送信回数が制限を超えたので、1時間後にお試しください。',
    E00005: '認証コードシステムエラーが発生しました。少し時間を置いてから再実行してください。'

}



const formatCheck = {
    Email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    Code: /^\d{6}$/,
    // Password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])\S{8,99}$/,
    Password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d|.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}$/,
    Phone: /0\d{1,4}-\d{1,4}-\d{4}/,
    ZipCode: /^[0-9]{3}-[0-9]{4}$/
}



export {
    routerNames,
    errorMessage,
    formatCheck,
    localstorage,
    successMessage
}