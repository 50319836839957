
import request from './request.js';

export const ApiToken = (data) => request.post('/token', { data })
export const ApiTokenForgotPassword = (data) => request.post('/token/forgot_password', { data })
export const ApiTokenConfirmForgotPassword = (data) => request.post('/token/confirm_forgot_password', { data })
export const ApiTokenForceChangePassword = (data) => request.post('/token/force_change_password', { data })
export const ApiTokenRefresh = (data) => request.post('/token/refresh', { data })

export const ApiMenuAuthorityInfo = (data) => request.get('/token/getMenuAuthority', { data })

export const ApiHelpFile = (data) => request.get('/homonJiseki/getHelpFile', { data })

export const ApiUserUserauthority = (data) => request.get('/user/userauthority', { data })

export const ApiHomonJisekiGetInfo = (data) => request.get('/homonJiseki/getInfo', { data })
export const ApiVisitAddInfo = (data) => request.get('/homonJiseki/addInfo', { data })
export const ApiVisitUpdateInfo = (data) => request.post('/homonJiseki/updateInfo', { data })

export const ApiMasterInfo = (data) => request.get('/master/getMasterInfo', { data })


export const ApiTeishutsuJokyoInfo = (data) => request.get('/homonMonth/getTeishutsuJokyoInfo', { data })

export const ApiHomonMonthInfo = (data) => request.get('/homonMonth/getInfo', { data })
export const ApiSubmitCsvDownload = (data) => request.get('/homonMonth/submitCsvDownload', { data })
export const ApiHomonMonthConfirmInfo = (data) => request.get('/homonMonth/getConfirmInfo', { data })
export const ApiHomonMonthDelete = (data) => request.get('/homonMonth/deleteInfo', { data })
export const ApiHomonMonthUpdate = (data) => request.post('/homonMonth/updateInfo', { data })

export const ApiTerminalFileAdd = (data) => request.post('/tanmatsuKanri/uploadTerminalFile', { data })
export const ApiTerminalList = (data) => request.get('/tanmatsuKanri/getList', { data })
export const ApiTerminalDeleteList = (data) => request.post('/tanmatsuKanri/deleteNewDataInfo', { data })
export const ApiAddTanmatsuInfo = (data) => request.post('/tanmatsuKanri/addTanmatsuInfo', { data })
export const ApiTerminalStatusQueryList = (data) => request.get('/tanmatsuKanri/getJtaiToiawaseInfo', { data })
export const ApiTerminalDetailInfo = (data) => request.get('/tanmatsuKanri/getInfo', { data })
export const ApiTerminalDetailAddInfo = (data) => request.post('/tanmatsuKanri/addInfo', { data })
export const ApiTerminalDetailUpdateInfo = (data) => request.post('/tanmatsuKanri/updateInfo', { data })
export const ApiTerminalCsvDownload = (data) => request.get('/tanmatsuKanri/download', { data })

export const ApiSupportContents = (data) => request.get('/homonMonth/getSupportContentsInfo', { data })

export const ApiGetFavorite = (data) => request.get('/homonJiseki/getFavorite', { data })
export const ApiAddFavorite = (data) => request.post('/homonJiseki/addFavorite', { data })
export const ApiDeleteFavorite = (data) => request.post('/homonJiseki/deleteFavorite', { data })

export const ApiTerminalBatchUpdate = (data) => request.post('/tanmatsuKanri/uploadTerminalUpdateFile', { data })
export const ApiStorageBatchUpload = (data) => request.post('/tanmatsuKanri/uploadStoragetanmatsuFile', { data })
export const ApiStorageBatchUpdate = (data) => request.post('/tanmatsuKanri/uploadStoragetanmatsuUpdateFile', { data })



