import axios from "axios";
import dealReturn from "./dealReturn";
import devConfig from "./config";
import ReactDOM from 'react-dom/client';
import CircularProgress from '@mui/material/CircularProgress';
import { message } from 'antd';

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// axios.defaults.headers["X-Frame-Options"] = "DENY";


// 显示loading
function showLoading() {
  var dom = document.createElement('div')
  dom.setAttribute('id', 'loading')
  document.body.appendChild(dom)
  ReactDOM.createRoot(dom).render(
    <div style={{ textAlign: 'center' }}>
      <CircularProgress />
      <div style={{ fontWeight: "bold", color: '#DDD' }}>処理中です</div>
      <div style={{ fontWeight: "bold", color: '#DDD' }}>しばらくお待ちください</div>
    </div>
  )
}

// 隐藏loading
function hideLoading() {
  if (document.getElementById('loading')) {
    document.body.removeChild(document.getElementById('loading'))
  }
}

axios.interceptors.request.use(
  async (config) => {
    if (config.url === "/token") {
      config.headers["Content-Type"] = "application/x-www-form-urlencoded;charset=utf-8";
    }
    else if (config.url === "/tanmatsuKanri/uploadTerminalFile" || config.url === "/tanmatsuKanri/uploadTerminalUpdateFile" || config.url === "/tanmatsuKanri/uploadStoragetanmatsuFile" || config.url === "/tanmatsuKanri/uploadStoragetanmatsuUpdateFile") {
      config.headers["Content-Type"] = "multipart/form-data";
      config.headers["authorization"] = "Bearer" + ' ' + localStorage.getItem('access_token')
    } else {
      config.headers["authorization"] = "Bearer" + ' ' + localStorage.getItem('access_token')
    }
    if (config.url !== "/homonJiseki/getHelpFile") {
      showLoading()
    }
    return config;
  },
  (error) => {
    hideLoading()
    return Promise.reject(error)
  }
);

axios.interceptors.response.use(
  (response) => {
    hideLoading()
    if (!response.status) {
      window.location.reload();
    }

    if (response.status === 200) {
      return response;
    }

    return dealReturn(response);
  },
  (error) => {
    hideLoading()
    // if (error.response.status === 401) {
    //   localStorage.clear()
    //   window.location.href = '/login'
    // }
    return dealReturn(error.response);
  }
);

const createBaseUrl = (() => {
  const { host, baseUrl } = devConfig;
  return host + baseUrl;
})();

const request = (method, url, param) => {
  let temp = {
    baseURL: createBaseUrl,
    timeout: 30 * 1000,
    withCredentials: false,
    method,
    url,
  };
  if (param) {
    temp = Object.assign(temp, param);
  }
  return axios.request(temp);
};

export default {
  get: (url, data) => {
    let urlParam = "";
    if (
      !data ||
      JSON.stringify(data) === "{}" ||
      !data.data ||
      JSON.stringify(data.data) === "{}"
    ) {
    } else {
      for (let key in data.data) {
        if (key) {
          if (!urlParam) {
            urlParam = "?" + key + "=" + data.data[key];
          } else {
            urlParam = urlParam + "&" + key + "=" + data.data[key];
          }
        }
      }
      url = url + urlParam;
    }
    return request("get", url, data);
  },
  post: (url, data) => request("post", url, data),
  put: (url, data) => request("put", url, data),
  delete: (url, data) => request("delete", url, data),
  patch: (url, data) => request("patch", url, data),
};
