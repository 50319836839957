import React, { useEffect, useState, useRef } from "react";
import { withStyles } from "@mui/styles";
import { routerNames, localstorage } from '../../wordsFile/wordsFile'
import { getSession, getUserPool, getCurrSession } from "../../network/UserPool";
import { VW, VH } from "../../componets/function/sizeAdapter"
import Stepper from '@mui/material/Stepper';
import sortIcon from "../../componets/icon/sortIcon.svg"
import visitIcon from "../../componets/icon/visitIcon.svg"
import transferIcon from "../../componets/icon/transferIcon.svg"
import supportIcon from "../../componets/icon/supportIcon.svg"
import { KeyboardArrowUpRounded, KeyboardArrowDownRounded, LocalLaundryService } from "@mui/icons-material";
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Modal, Button, Input, DatePicker, message } from 'antd';
import { Box } from "@mui/material";
import log from "../../componets/function/log";
import timeFormat from "../../componets/function/timeFormat";
import { useNavigate, useLocation } from "react-router-dom";
import {
    kyuka_kubunOption,
    support_kubunOption,
    statusOption,
    gyomu_shuruiOption,
    teiji_jikanOption,
    jikangaiOption,
    kotsu_kikanOption
} from "../../wordsFile/selectItem";

import routeIcon from "../../componets/icon/routeIcon.svg"

import { ApiHomonJisekiGetInfo } from "../../network/api";
import Common_errorAlert from "../../componets/common/Common_errorAlert"

const styles = (theme) => ({
    ground: {
        height: '100%',
        paddingTop: VH(25),
        paddingLeft: VW(24),
        paddingRight: VW(24)
    },
    icon: {
        width: VW(32),
        height: VH(32),
        marginRight: VW(14)
    },
    iconContainer: {
        transform: 'scale(1.667)',
    },
    itemMonthlyHead: {
        color: "#27303D",
        fontSize: VW(24),
        fontWeight: 700,
        display: "flex",
        alignItems: "center",
        paddingLeft: VW(10)
        // marginLeft: VW(24),
        // marginRight: VW(24)
    },
    bar: {
        display: "flex",
        alignItems: "center",
        height: VH(64),
        backgroundColor: "#ffffff",
        borderBottom: '1px solid #E0E0E0',
        justifyContent: 'space-between',
        paddingLeft: VW(12),
        paddingRight: VW(16),
    },
    itembar: {
        width: "100%",
        display: "flex",
        alignItems: "center",
    },
    item: {
        // width: VW(732),
        width: "100%",
        display: "flex",
        alignItems: "center",
        borderTop: '1px solid #E0E0E0',
        marginLeft: VW(24),
        // paddingRight: VW(24)
    },
    itemhead: {
        // width: VW(845),
        display: "flex",
        alignItems: "center",
        borderTop: '1px solid #E0E0E0',
        // marginLeft: VW(24),
        // marginRight: VW(24)
    },
    itemone: {
        display: "flex",
        alignItems: "center",
        borderTop: '1px solid #E0E0E0',
        borderBottom: '1px solid #E0E0E0',
        marginLeft: VW(24),
        // marginRight: VW(24)
    },
    itemTextHead: {
        color: "#27303D",
        fontSize: VW(16),
        fontWeight: 700,
        // width: VW(240),
        width: "32.8%",
        height: VH(58),
        backgroundColor: "#E9F4FB",
        display: "flex",
        alignItems: "center",
        padding: VW(16)
    },
    itemTextHeadBody: {
        // width: VW(492),
        width: "67.2%",
        height: VH(58),
        fontSize: VW(16),
        fontWeight: 400,
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        padding: VW(16)
    },
    itemText: {
        color: "#27303D",
        fontSize: VW(16),
        fontWeight: 700,
        // width: VW(240),
        width: "28.4%",
        height: VH(56),
        backgroundColor: "#E9F4FB",
        display: "flex",
        alignItems: "center",
        padding: VW(16)
    },
    itemInput: {
        // width: VW(605),
        width: "71.6%",
        height: VH(56),
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        padding: VW(16)
    },
    itemTextOne: {
        color: "#27303D",
        fontSize: VW(16),
        fontWeight: 700,
        // width: VW(240),
        width: "32.8%",
        height: VH(62),
        backgroundColor: "#E9F4FB",
        display: "flex",
        alignItems: "center",
        padding: VW(16)
    },
    itemInputOne: {
        // width: VW(492),
        width: "67.2%",
        height: VH(62),
        fontSize: VW(16),
        fontWeight: 400,
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        padding: VW(16)
    },
    itemTextAll: {
        color: "#27303D",
        fontSize: VW(16),
        fontWeight: 700,
        height: VH(104),
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        paddingRight: VW(24),
        // marginTop: VH(32),
        marginTop: VH(24),
        borderTop: '1px solid #E0E0E0',
        marginLeft: VW(24),
        // marginRight: VW(24),
        borderRadius: VW(5)
    },
    itemVisitOne: {
        width: "100%",
        // width: VW(773),
        color: "#27303D",
        fontSize: VW(16),
        fontWeight: 700,
        display: "flex",
        alignItems: "center",
        marginLeft: VW(24),
        paddingRight: VW(24),

    },
    itemvisit: {
        width: "100%",
        // width: VW(783),
        display: "flex",
        alignItems: "center",
        borderLeft: '1px solid #E0E0E0',
        marginLeft: VW(24),
        marginRight: VW(24),

    },
    itemtTransportation: {
        // width: VW(780),
        width: "100%",
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        marginTop: VH(24),
        marginLeft: VW(24),
        marginRight: VW(24),
        borderRadius: VW(5)
    },


    buttonGroup1: {
        borderRadius: "4px",
        width: VW(240),
        height: VH(50),
        fontSize: VW(16),
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`
    },
    buttonGroup3: {
        color: '#FFF',
        backgroundColor: theme.palette.primary.main,
        marginLeft: VW(16)
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    },
    buttonGroup2: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            width: VW(533),
            height: VH(56),
            borderRadius: VW(4),
            fontSize: VW(16),
            fontWeight: 700,
            backgroundColor: theme.palette.primary.main,
            color: '#FFF',
            border: `1px solid #2195D3`,
        }
    }
});

const steps = [
    '訪問先の登録',
    '登録内容の確認',
    '登録完了',
];

function MonthlyRecordConfirmDetails(props) {
    const {
        classes,
        setSelectFlag,
        masterData,
        menuFlag
    } = props
    const navigate = useNavigate()
    const location = useLocation();
    const { state } = location;
    let startNameNum = 0
    let endNameNum = 0
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')
    const [visitData, setVisitData] = useState()

    useEffect(() => {
        setSelectFlag(routerNames.MonthlyVisitingRecord)
        getCurrSession()
        document.getElementById('father_Block').scrollTop = 0;
        startNameNum = 0
        endNameNum = 0
        getVisitInfo()
    }, [setSelectFlag])



    const getVisitInfo = () => {
        let params = {
            user_id: state.userID,
            homon_day: state.businessDay
        }
        ApiHomonJisekiGetInfo(params).then(res => {
            let resData = res.data
            log(res)
            if (res.status === 200) {
                for (let index = 0; index < resData?.homon_jiseki?.length; index++) {
                    resData.homon_jiseki[index].name = "訪問先 " + (index + 1)
                    resData.homon_jiseki[index].supportList.map((supportItem) => {
                        supportItem.expand = 1
                    })
                }
                setVisitData({ ...resData })
            }
        }).catch(err => {

        })
    }
    const updateVisitSupportData = (item, index, suportIndex, flag) => {
        visitData.homon_jiseki[index].supportList[suportIndex][flag] = item
        setVisitData({ ...visitData })
    }
    const updateVisitRouteData = (item, index, routeIndex, flag) => {
        visitData.homon_jiseki[index].kotsukikanList[routeIndex][flag] = item
        setVisitData({ ...visitData })
    }
    return (
        <div className={classes.ground}>
            <div className={classes.itemMonthlyHead} style={{ borderLeft: '5px solid #2195D3', fontWeight: 'bold' }}>
                {state != null && state.riyosya_name !== "" ? state.riyosya_name + 'さんの月間訪問実績' : '月間訪問実績'}
            </div>
            <div style={{ marginTop: VH(32), width: "100%" }}>
                <div className={classes.itemhead}>
                    <div className={classes.itemText}>訪問日</div>
                    <div className={classes.itemInput}>
                        <div>{visitData ? timeFormat.strDay_to_strDay(visitData?.homon_jiseki[0]?.homon_day) : ""}</div>
                    </div>
                </div>

                <div className={classes.itemhead}>
                    <div className={classes.itemText}>休暇区分</div>
                    <div className={classes.itemInput}>
                        <div>{kyuka_kubunOption.map((item, index) => {
                            return (
                                visitData && visitData.homon_jiseki[0].kyuka_kubun === item.value ? item.label : ''
                            )
                        })}
                        </div>
                    </div>
                </div>
                <div className={classes.itemhead} style={{ borderBottom: '1px solid #E0E0E0' }}>
                    <div className={classes.itemText}>登録者名</div>
                    <div className={classes.itemInput}>
                        <div>{visitData && visitData.homon_jiseki[0].user_name}</div>
                    </div>
                </div>
            </div>

            {visitData && visitData?.homon_jiseki?.map((item, index) => {
                startNameNum = 0
                endNameNum = 0
                return (
                    <div key={index}>
                        <div className={classes.itemTextAll}>
                            <div style={{ marginTop: VH(2), width: "100%" }}>
                                <div className={classes.itemone}>
                                    <div className={classes.itemTextHead}>出発地</div>
                                    <div className={classes.itemTextHeadBody}>
                                        <div>{item.homon_shuppatsu} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.itemVisitOne}>
                            <div style={{ width: "100%" }}>

                                <div className={classes.itemvisit} >

                                    <div className={classes.itemtTransportation}>

                                        <div style={{ width: "100%" }}>

                                            <div className={classes.itembar}>
                                                <div style={{ width: "100%" }}>
                                                    <div className={classes.bar} style={{ width: "100%" }}>
                                                        <div style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}>
                                                            <img
                                                                src={sortIcon}
                                                                className={classes.icon}
                                                            />
                                                            <img
                                                                src={transferIcon}
                                                                className={classes.icon}
                                                            />
                                                            <div
                                                                style={{
                                                                    color: "#053550",
                                                                    fontWeight: 700,
                                                                    fontSize: VW(20)
                                                                }}>経路（往路）</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {item.kotsukikanList.map((routeItem, routeIndex) => {
                                                if (routeItem.ofuku === 1) {
                                                    startNameNum++
                                                    return (
                                                        <div key={routeIndex}>

                                                            <div className={classes.bar} style={{ border: 'none ', paddingLeft: VW(24), height: VH(30), marginTop: VH(16), marginBottom: VH(16) }}
                                                                onClick={() => updateVisitRouteData(routeItem.expand === 0 ? 1 : 0, index, routeIndex, 'expand')}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    alignItems: "center"
                                                                }}
                                                                >
                                                                    <img
                                                                        src={routeIcon}
                                                                        style={{
                                                                            width: VW(16),
                                                                            height: VH(16),
                                                                            marginRight: VW(14)
                                                                        }}
                                                                    />
                                                                    <div
                                                                        style={{
                                                                            color: "#053550",
                                                                            fontWeight: 700,
                                                                            fontSize: VW(18)
                                                                        }}>{"移動手段 " + startNameNum}</div>
                                                                </div>
                                                                {routeItem.expand === 0 ? <KeyboardArrowDownRounded /> : <KeyboardArrowUpRounded />}

                                                            </div>
                                                            <div style={{ marginBottom: VH(24), marginTop: VH(16), marginRight: VW(24), display: routeItem.expand === 0 ? "" : "none" }}>
                                                                <div className={classes.item}>
                                                                    <div className={classes.itemTextOne}>高速料金 / 運賃</div>
                                                                    <div className={classes.itemInputOne} style={{ display: "flex", justifyContent: "flex-end", fontSize: VW(20) }}>
                                                                        <div>{routeItem.unchin_kosoku_ryokin || "0"} </div>
                                                                        <div style={{ fontSize: VW(14) }}>  {"\xa0円"} </div>
                                                                    </div>
                                                                </div>

                                                                <div className={classes.item}>
                                                                    <div className={classes.itemTextOne}>交通機関</div>
                                                                    <div className={classes.itemInputOne}>
                                                                        <div>{kotsu_kikanOption.map((item, i) => {
                                                                            if (item.value === routeItem.kotsukikan_code) {
                                                                                return (
                                                                                    item.label
                                                                                )
                                                                            } else {
                                                                                return null
                                                                            }
                                                                        })} </div>
                                                                    </div>
                                                                </div>
                                                                <div className={classes.item}>
                                                                    <div className={classes.itemTextOne}>出発地：IC / 駅 / バス停</div>
                                                                    <div className={classes.itemInputOne}>
                                                                        <div>{routeItem.shuppatsu_ic || ""}</div>
                                                                    </div>
                                                                </div>
                                                                <div className={classes.item} style={{ borderBottom: '1px solid #E0E0E0' }}>
                                                                    <div className={classes.itemTextOne}>到着地：IC / 駅 / バス停</div>
                                                                    <div className={classes.itemInputOne}>
                                                                        <div>{routeItem.tochaku_ic || ""}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                } else {
                                                    return null
                                                }

                                            })}
                                        </div>
                                    </div>
                                </div>




                                <div className={classes.itemvisit}>
                                    <div className={classes.itemtTransportation}>
                                        <div style={{ width: "100%" }}>
                                            <div className={classes.itembar}>
                                                <div style={{ width: "100%" }}>
                                                    <div className={classes.bar}>
                                                        <div style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}>
                                                            <img
                                                                src={sortIcon}
                                                                className={classes.icon}
                                                            />
                                                            <img
                                                                src={visitIcon}
                                                                className={classes.icon}
                                                            />
                                                            <div
                                                                style={{
                                                                    color: "#053550",
                                                                    fontWeight: 700,
                                                                    fontSize: VW(20)
                                                                }}>{item.name + "の実績"}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ marginTop: VH(24), marginRight: VW(24), }}>
                                                <div className={classes.item}>
                                                    <div className={classes.itemTextOne}>対象校</div>
                                                    <div className={classes.itemInputOne}>
                                                        <div>{masterData.gako_info.map((temp, i) => {
                                                            return (
                                                                temp.no === item.homon_saki ? temp.gakoMeisho : null
                                                            )
                                                        })}</div>
                                                    </div>
                                                </div>

                                                <div className={classes.item}>
                                                    <div className={classes.itemTextOne}>事業区分</div>
                                                    <div className={classes.itemInputOne}>
                                                        <div>{masterData.jigyoKubunOptionList.map((temp, i) => {
                                                            return (
                                                                temp.value === item.jigyo_kubun ? temp.label : null
                                                            )
                                                        })}</div>
                                                    </div>
                                                </div>
                                                <div className={classes.item}>
                                                    <div className={classes.itemTextOne}>業務の種類</div>
                                                    <div className={classes.itemInputOne}>
                                                        <div>{gyomu_shuruiOption.map((temp, i) => {
                                                            return (
                                                                temp.value === item.gyomu_shurui ? temp.label : null
                                                            )
                                                        })}</div>
                                                    </div>
                                                </div>
                                                <div className={classes.item}>
                                                    <div className={classes.itemTextOne}>作業場所</div>
                                                    <div className={classes.itemInputOne}>
                                                        <div>{item.sagyo_basho === null ?
                                                            masterData.gako_info.map((row) => {
                                                                if (row.no == item.homon_saki) {
                                                                    return (
                                                                        row.gakoMeisho
                                                                    )
                                                                }
                                                            })
                                                            :
                                                            item.sagyo_basho === '0' ? item.sagyo_basho_sonota :
                                                                masterData.sagyobasyo_info.map((temp, i) => {
                                                                    return (
                                                                        temp.no === item.sagyo_basho ? temp.homonsakiMeisho : null
                                                                    )
                                                                })}</div>
                                                    </div>
                                                </div>
                                                <div className={classes.item}>
                                                    <div className={classes.itemTextOne}>作業内容</div>
                                                    <div className={classes.itemInputOne}>
                                                        <div>{item.sagyo_naiyo}</div>
                                                    </div>
                                                </div>
                                                <div className={classes.item}>
                                                    <div className={classes.itemTextOne}>訪問区分</div>
                                                    <div className={classes.itemInputOne}>
                                                        <div>{teiji_jikanOption.map((temp, i) => {
                                                            return (
                                                                temp.value === item.teiji_jikan ? temp.label : null
                                                            )
                                                        })}</div>
                                                    </div>
                                                </div>
                                                <div className={classes.item} style={{ borderBottom: '1px solid #E0E0E0', marginBottom: VH(16) }}>
                                                    <div className={classes.itemTextOne}>時間外</div>
                                                    <div className={classes.itemInputOne}>
                                                        <div>{jikangaiOption.map((temp, i) => {
                                                            return (
                                                                temp.value === item.jikangai ? `${temp.label}時間` : null
                                                            )
                                                        })}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            {item.supportList.map((supportItem, supportIndex) => {

                                                return (
                                                    <div key={supportIndex} >
                                                        {/* 支援内容 */}
                                                        <div className={classes.itembar}>
                                                            <div style={{ marginBottom: VH(15), width: "100%" }}>
                                                                <div className={classes.bar} onClick={() => updateVisitSupportData(supportItem.expand === 0 ? 1 : 0, index, supportIndex, 'expand')}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}>
                                                                        <img
                                                                            src={supportIcon}
                                                                            className={classes.icon}
                                                                        />
                                                                        <div
                                                                            style={{
                                                                                color: "#053550",
                                                                                fontWeight: 700,
                                                                                fontSize: VW(20)
                                                                            }}>{"支援内容" + (supportIndex + 1).toString()} </div>
                                                                    </div>
                                                                    {supportItem.expand === 0 ? <KeyboardArrowDownRounded /> : <KeyboardArrowUpRounded />}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginBottom: VH(24), marginRight: VW(24), display: supportItem.expand === 0 ? "" : "none" }}>
                                                            <div className={classes.item}>
                                                                <div className={classes.itemTextOne}>支援区分</div>
                                                                <div className={classes.itemInputOne}>
                                                                    <div>{support_kubunOption.map((item, index) => {
                                                                        return (
                                                                            item.value === supportItem.sienkubun_code ? item.label : null
                                                                        )
                                                                    })}</div>
                                                                </div>
                                                            </div>

                                                            <div className={classes.item}>
                                                                <div className={classes.itemTextOne}>教科</div>
                                                                <div className={classes.itemInputOne}>
                                                                    <div>{masterData.subject_info.map((item, index) => {
                                                                        return (
                                                                            item.no === supportItem.kyoka_code ? item.kyoka : null
                                                                        )
                                                                    })}</div>
                                                                </div>
                                                            </div>
                                                            <div className={classes.item}>
                                                                <div className={classes.itemTextOne}>学年</div>
                                                                <div className={classes.itemInputOne}>
                                                                    <div>{masterData.gakunen_info.map((item, index) => {
                                                                        return (
                                                                            item.no === supportItem.gakunen_code ? item.gakunen : null
                                                                        )
                                                                    })}</div>
                                                                </div>
                                                            </div>
                                                            <div className={classes.item}>
                                                                <div className={classes.itemTextOne}>依頼者</div>
                                                                <div className={classes.itemInputOne}>
                                                                    <div>{supportItem.requester}</div>
                                                                </div>
                                                            </div>
                                                            <div className={classes.item}>
                                                                <div className={classes.itemTextOne}>ICT環境1</div>
                                                                <div className={classes.itemInputOne}>
                                                                    <div>{masterData.ictEnvironment_info.map((item, index) => {
                                                                        return (
                                                                            item.no === supportItem.ict_kankyo_code1 ? item.ictKankyou : ""
                                                                        )
                                                                    })}</div>
                                                                </div>
                                                            </div>
                                                            <div className={classes.item}>
                                                                <div className={classes.itemTextOne}>ICT環境2</div>
                                                                <div className={classes.itemInputOne}>
                                                                    <div>{masterData.ictEnvironment_info.map((item, index) => {
                                                                        return (
                                                                            item.no === supportItem.ict_kankyo_code2 ? item.ictKankyou : ""
                                                                        )
                                                                    })}</div>
                                                                </div>
                                                            </div>
                                                            <div className={classes.item}>
                                                                <div className={classes.itemTextOne}>ソフトウェア1</div>
                                                                <div className={classes.itemInputOne}>
                                                                    <div>{masterData.software_info.map((item, index) => {
                                                                        return (
                                                                            item.no === supportItem.software_code1 ? item.software : null
                                                                        )
                                                                    })}</div>
                                                                </div>
                                                            </div>
                                                            <div className={classes.item}>
                                                                <div className={classes.itemTextOne}>ソフトウェア2</div>
                                                                <div className={classes.itemInputOne}>
                                                                    <div>{masterData.software_info.map((item, index) => {
                                                                        return (
                                                                            item.no === supportItem.software_code2 ? item.software : null
                                                                        )
                                                                    })}</div>
                                                                </div>
                                                            </div>
                                                            <div className={classes.item} style={{ backgroundColor: "#E9F4FB", }}>
                                                                <div style={{
                                                                    color: "#27303D",
                                                                    fontSize: VW(16),
                                                                    fontWeight: 700,
                                                                    // width: VW(240),
                                                                    width: "32.8%",
                                                                    height: "100%",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    backgroundColor: "#E9F4FB",
                                                                    paddingLeft: VW(16)
                                                                }}>具体的内容</div>
                                                                <div style={{
                                                                    // width: VW(492),
                                                                    width: "67.2%",
                                                                    minHeight: VH(62),
                                                                    fontSize: VW(16),
                                                                    fontWeight: 400,
                                                                    backgroundColor: "#ffffff",
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    padding: VW(16)
                                                                }}>
                                                                    <div>{supportItem.support}</div>
                                                                </div>
                                                            </div>
                                                            <div className={classes.item} style={{ borderBottom: '1px solid #E0E0E0' }}>
                                                                <div className={classes.itemTextOne}>状況</div>
                                                                <div className={classes.itemInputOne}>
                                                                    <div>{statusOption.map((item, index) => {
                                                                        return (
                                                                            item.value === supportItem.status ? item.label : null
                                                                        )
                                                                    })}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    </div>
                                </div>


                                <div className={classes.itemvisit} style={{ paddingBottom: VH(24) }}>

                                    {/* <div className={classes.itemvisit}> */}

                                    <div className={classes.itemtTransportation}>

                                        <div style={{ width: "100%" }}>

                                            <div className={classes.itembar}>
                                                <div style={{ width: "100%" }}>
                                                    <div className={classes.bar} style={{ width: VW(780) }}>
                                                        <div style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                        }}>
                                                            <img
                                                                src={sortIcon}
                                                                className={classes.icon}
                                                            />
                                                            <img
                                                                src={transferIcon}
                                                                className={classes.icon}
                                                            />
                                                            <div
                                                                style={{
                                                                    color: "#053550",
                                                                    fontWeight: 700,
                                                                    fontSize: VW(20)
                                                                }}>復路</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {item.kotsukikanList.map((routeItem, routeIndex) => {
                                                if (routeItem.ofuku === 2) {
                                                    endNameNum++
                                                    return (
                                                        <div key={routeIndex}>
                                                            <div className={classes.bar} style={{ border: 'none ', paddingLeft: VW(24), height: VH(30), marginTop: VH(16), marginBottom: VH(16) }}
                                                                onClick={() => updateVisitRouteData(routeItem.expand === 0 ? 1 : 0, index, routeIndex, 'expand')}>
                                                                <div style={{
                                                                    display: "flex",
                                                                    alignItems: "center"
                                                                }}
                                                                >
                                                                    <img
                                                                        src={routeIcon}
                                                                        style={{
                                                                            width: VW(16),
                                                                            height: VH(16),
                                                                            marginRight: VW(14)
                                                                        }}
                                                                    />
                                                                    <div
                                                                        style={{
                                                                            color: "#053550",
                                                                            fontWeight: 700,
                                                                            fontSize: VW(18)
                                                                        }}>{"移動手段 " + endNameNum}</div>
                                                                </div>
                                                                {routeItem.expand === 0 ? <KeyboardArrowDownRounded /> : <KeyboardArrowUpRounded />}

                                                            </div>
                                                            <div style={{ marginBottom: VH(24), marginTop: VH(16), marginRight: VW(24), display: routeItem.expand === 0 ? "" : "none" }}>
                                                                <div className={classes.item}>
                                                                    <div className={classes.itemTextOne}>高速料金 / 運賃</div>
                                                                    <div className={classes.itemInputOne} style={{ display: "flex", justifyContent: "flex-end", fontSize: VW(20) }}>
                                                                        <div>{routeItem.unchin_kosoku_ryokin || "0"} </div>
                                                                        <div style={{ fontSize: VW(14) }}>  {"\xa0円"} </div>
                                                                    </div>
                                                                </div>

                                                                <div className={classes.item}>
                                                                    <div className={classes.itemTextOne}>交通機関</div>
                                                                    <div className={classes.itemInputOne}>
                                                                        <div>{kotsu_kikanOption.map((item, i) => {
                                                                            if (item.value === routeItem.kotsukikan_code) {
                                                                                return (
                                                                                    item.label
                                                                                )
                                                                            } else {
                                                                                return null
                                                                            }
                                                                        })} </div>
                                                                    </div>
                                                                </div>
                                                                <div className={classes.item}>
                                                                    <div className={classes.itemTextOne}>出発地：IC / 駅 / バス停</div>
                                                                    <div className={classes.itemInputOne}>
                                                                        <div>{routeItem.shuppatsu_ic || ""}</div>
                                                                    </div>
                                                                </div>
                                                                <div className={classes.item} style={{ borderBottom: '1px solid #E0E0E0' }}>
                                                                    <div className={classes.itemTextOne}>到着地：IC / 駅 / バス停</div>
                                                                    <div className={classes.itemInputOne}>
                                                                        <div>{routeItem.tochaku_ic || ""}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                } else {
                                                    return null
                                                }

                                            })}
                                        </div>
                                    </div>
                                    {/* </div> */}

                                </div>
                            </div>
                        </div>

                        <div className={classes.itemTextAll} style={{ marginTop: VH(0) }}>
                            <div style={{ marginTop: VH(2), width: "100%" }}>
                                <div className={classes.itemone}>
                                    <div className={classes.itemTextHead}>到着地</div>
                                    <div className={classes.itemTextHeadBody}>
                                        <div>
                                            {item.kotsukikanList.map((routeItem, routeIndex) => {
                                                if ((routeIndex == 0 && index == 0)
                                                    || (routeIndex == 0 && index == 1)) {
                                                    return (item.homon_tochaku_sonota)
                                                }
                                            })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}

            <div className={classes.buttonGroup} style={{ marginBottom: VH(40), paddingBottom: VH(40) }}>
                <div style={{ width: "50%", paddingLeft: VW(24) }}>
                    <Button className={classes.buttonGroup1} style={{
                        borderRadius: "4px", width: VW(400), height: VH(56), fontSize: VW(16),
                        fontWeight: 'bold',
                    }} onClick={() => { window.history.back() }} >月間訪問実績一覧に戻る</Button>
                </div>
            </div>

        </div >
    )
}

MonthlyRecordConfirmDetails.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(MonthlyRecordConfirmDetails);
