import React, { useEffect, useState } from "react";
import { withStyles } from "@mui/styles";
import { routerNames, localstorage } from '../../wordsFile/wordsFile'
import { getCurrSession } from "../../network/UserPool";
import { Input, DatePicker, message } from 'antd';
import { VW, VH } from "../../componets/function/sizeAdapter"

import { TextField } from "@mui/material";
import log from "../../componets/function/log";
import { CalendarToday, CloseRounded } from "@mui/icons-material";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { ApiSupportContents } from "../../network/api";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Clear";
import pako from 'pako';
import Common_successAlert from "../../componets/common/Common_successAlert";
import Common_errorAlert from "../../componets/common/Common_errorAlert";
import iconv from 'iconv-lite';

import {
    MenuItem, Select,
    List,
    ListItemButton,
    Collapse,
    Autocomplete
} from "@mui/material";
import { SearchRounded, FileDownloadRounded } from "@mui/icons-material";
import { ownerNo_Option, break_Option, support_kubunOption } from "../../wordsFile/selectItem";
import Pagination from "@mui/material/Pagination";
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
const { TextArea } = Input;

const styles = (theme) => ({
    ground: {
        height: '100%',
        paddingTop: "8px",
        paddingLeft: "24px",
        paddingRight: "24px"
    },
    itemMonthlyHead: {
        color: "#27303D",
        fontSize: "24px",
        fontWeight: 700,
        display: "flex",
        alignItems: "center",
        paddingLeft: "10px"
    },
    item: {
        display: "flex",
        alignItems: "center",
        borderTop: '1px solid #E0E0E0',
        borderBottom: '1px solid #E0E0E0',
    },
    itemText: {
        color: "#ffffff",
        fontSize: "12px",
        fontWeight: 700,
        width: "80px",
        height: "42px",
        backgroundColor: "#2095D4",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "8px",
    },
    itemInput: {
        width: "390px",
        height: "42px",
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        paddingLeft: "8px",
        fontSize: "12px",
    },
    select: {
        "&:hover": {
            "&& fieldset": {
                border: "1px solid #4096ff"
            }
        },
        "&.MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                border: "1px solid #4096ff"
            }
        }
    },
    page: {
        "& .MuiPaginationItem-root": {
            color: "#000000",
        }
    },
    autocomplete: {
        "&:hover": {
            "&& fieldset": {
                border: "1px solid #4096ff"
            }
        },
        "&.MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                border: "1px solid #4096ff"
            }
        },
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
            height: "26px",
            padding: 0
        },
        '& .MuiInputBase-root': {
            height: "26px",
            backgroundColor: "#fff"
        },
    },
    scrollbar: {
        '& ::-webkit-scrollbar': {
            width: "20px",
            height: "20px"
        },
        '& ::-webkit-scrollbar-thumb': {
            borderRadius: "5px",
            backgroundColor: "#808080",
            height: "80px"
        }
    },
    tableContainer: {
        overflow: 'auto',
    },
    table: {
        borderCollapse: 'collapse',
        width: '100%',
    },
    thTd: {
        border: '1px solid #ddd',
        padding: 8,
        textAlign: 'left',
        whiteSpace: 'nowrap',
    },
    fixedColumn: {
        position: 'sticky',
        left: 0,
        backgroundColor: 'white',
        zIndex: 2,
    },
    fixedColumn2: {
        position: 'sticky',
        left: 0,
        backgroundColor: 'white',
        zIndex: 2,
    },
    fixedHeader: {
        position: 'sticky',
        top: 0,
        backgroundColor: '#f4f4f4',
        zIndex: 1,
    },
    fixedColumnFixedHeader: {
        zIndex: 3,
    },
    fixedColumn2FixedHeader: {
        zIndex: 3,
    },
});


//Comparer Function 升順
function GetSortDownOrder(prop) {
    return function (a, b) {
        if (a[prop] > b[prop]) {
            return 1;
        } else if (a[prop] < b[prop]) {
            return -1;
        }
        return 0;
    }
}
//Comparer Function 降順
function GetSortUpOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
}

function unzip(b64Data) {
    var strData = atob(b64Data);
    var charData = strData.split('').map(function (x) { return x.charCodeAt(0); });
    var binData = new Uint8Array(charData);
    // unzip
    var data = pako.inflate(binData);
    var strData = new TextDecoder("utf-8").decode(data);
    return decodeURIComponent(strData);
}

function SupportContents(props) {
    const {
        classes,
        setSelectFlag,
        masterData
    } = props

    const location = useLocation();
    const navigate = useNavigate()

    const { state } = location;
    const [BulkRegistrationDialogOpen, setBulkRegistrationDialogOpen] = useState(false)
    const [userName, setUserName] = useState(""); // user name
    const [gakoCode, setGakoCode] = useState(""); // 学校Code
    const [sienkubun_code, setSienkubun_code] = useState(""); // 支援区分
    const [kyoka_code, setKyoka_code] = useState(""); // 教科
    const [ict_kankyo_code, setIct_kankyo_code] = useState([]); // ICT環境
    const [software_code, setSoftware_code] = useState([]); // ソフトウェア
    const [formDate, setFormDate] = useState(""); // 訪問日form
    const [toDate, setToDate] = useState(""); // 訪問日to
    const [keyword, setKeyword] = useState(""); // 検索キーワード
    const [divHeight, setHeight] = useState(100);

    const changeFormDateValue = (value) => {
        setFormDate(value)
    }

    const changeToDateValue = (value) => {
        setToDate(value)
    }
    const [searchData, setSearchData] = useState([]);
    const [showData, setShowData] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);

    const itemsPerPage = 100;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const getGakoList = async () => {
        let list = []
        masterData?.gako_info?.map((item, index) => {
            if (item.homonJissekiFuragu == "1") {
                list.push(item)
            }
        })
        return list;
    };

    const [headerList, setHeaderList] = useState([
        {
            "name": "訪問日",
            "value": null,
            "code": null,
            "data": null,
            "width1": "164px",
            "key": "homon_day",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "支援区分",
            "value": null,
            "code": null,
            "data": ownerNo_Option,
            "width1": "164px",
            "key": "sienkubun_code",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "具体的内容",
            "value": null,
            "code": null,
            "data": null,
            "width1": "713px",
            "key": "support",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "サポーター名",
            "value": null,
            "code": null,
            "data": null,
            "width1": "173px",
            "key": "user_name",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "学校名",
            "value": null,
            "code": null,
            "data": getGakoList(),
            "width1": "268px",
            "key": "homon_saki",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "教科",
            "value": null,
            "code": null,
            "data": null,
            "width1": "164px",
            "key": "kyoka_code",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "ICT環境1",
            "value": null,
            "code": null,
            "data": null,
            "width1": "164px",
            "key": "ict_kankyo_code1",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "ICT環境2",
            "value": null,
            "code": null,
            "data": break_Option,
            "width1": "164px",
            "key": "ict_kankyo_code2",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "ソフトウェア1",
            "value": null,
            "code": null,
            "data": null,
            "width1": "164px",
            "key": "software_code1",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "ソフトウェア2",
            "value": null,
            "code": null,
            "data": null,
            "width1": "164px",
            "key": "software_code2",
            "checkBox": false,
            "sortKey": "down"
        },
        {
            "name": "依頼者",
            "value": null,
            "code": null,
            "data": null,
            "width1": "164px",
            "key": "requester",
            "checkBox": false,
            "sortKey": "down"
        }
    ]);

    useEffect(() => {
        setSelectFlag(routerNames.SupportContents)
        getCurrSession()
        setSubjectList(masterData.subject_info.filter((item, index, array) =>
            array.findIndex((i) => i.kyoka === item.kyoka) === index
        ))
        setIctList(masterData.ictEnvironment_info.filter((item, index, array) =>
            array.findIndex((i) => i.ictKankyou === item.ictKankyou) === index
        ))
        setSoftWareList(masterData.software_info.filter((item, index, array) =>
            array.findIndex((i) => i.software === item.software) === index
        ))
        if (localStorage.getItem(localstorage.authority_id1) != "1" && localStorage.getItem(localstorage.authority_id2) != "1" && localStorage.getItem(localstorage.authority_id3) != "1") {
            localStorage.clear()
            window.location.href = '/login'
        }
        // getListData()
    }, [setSelectFlag])

    const [windowHeight, setWindowHeight] = useState(305);

    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')

    const [subjectList, setSubjectList] = useState([])

    const [ictList, setIctList] = useState([])

    const [softWareList, setSoftWareList] = useState([])

    window.addEventListener('resize', () => {
        let height = 305
        let innerHeight = window.innerHeight
        if (innerHeight <= 720) {
            setWindowHeight(305);
        } else {
            while (innerHeight - 720 > 50) {
                height += 50;
                innerHeight -= 50;
            }
            setWindowHeight(height);
        }
    });
    function getDivHeightById(id) {
        const div = document.getElementById(id);
        if (div) {
            return div.clientHeight;
        } else {
            return null;
        }
    }
    const getListData = () => {
        let params = {
            user_name: userName,
            homon_saki: gakoCode,
            sienkubun_code: sienkubun_code,
            kyoka_code: kyoka_code,
            ict_kankyo_code: ict_kankyo_code.join(','),
            software_code: software_code.join(','),
            form_date: formDate != '' && formDate != null ? formDate.format("YYYY-MM-DD") : '',
            to_date: toDate != '' && toDate != null ? toDate.format("YYYY-MM-DD") : '',
            keyword: keyword,
        }
        if (formDate != '' && formDate != null && toDate != '' && toDate != null && formDate > toDate) {
            setRrrorOpen(true)
            setErrorContent("終了日は開始日よりも先の日付に設定してください。")
            return
        }
        ApiSupportContents(params).then(res => {
            let resData = res.data
            if (res.status === 200) {
                setSearchData(resData.support_contents_info)
                setCurrentPage(1)
                setShowData(resData.support_contents_info.slice(startIndex, endIndex))

            }
        }).catch(err => {
            setRrrorOpen(true)
            setErrorContent("検索処理は予期せぬエラーが発生しました。")
            return

        })
    }
    const [sortKey, setSortKey] = useState();
    //ソート順実現メソッド
    const onSortChange = (itemkey) => {
        return
        if (showData?.length > 0) {
            let tempSearchData = searchData
            let tempShowData = showData
            if (sortKey) {
                if (sortKey.key != itemkey) {
                    if (itemkey == "gako_code") {
                        tempSearchData.sort((a, b) => a[`${itemkey}`] - b[`${itemkey}`]);
                    } else {
                        tempSearchData.sort((a, b) => a[`${itemkey}`].toString().localeCompare(b[`${itemkey}`]));
                    }
                    setSortKey({ key: itemkey, sort: "up" })
                } else {
                    if (sortKey.sort == "up") {
                        if (itemkey == "gako_code") {
                            tempSearchData.sort((a, b) => b[`${itemkey}`] - a[`${itemkey}`]);
                        } else {
                            tempSearchData.sort((a, b) => b[`${itemkey}`].toString().localeCompare(a[`${itemkey}`]));
                        }
                        setSortKey({ key: itemkey, sort: "down" })
                    } else {
                        if (itemkey == "gako_code") {
                            tempSearchData.sort((a, b) => a[`${itemkey}`] - b[`${itemkey}`]);
                        } else {
                            tempSearchData.sort((a, b) => a[`${itemkey}`].toString().localeCompare(b[`${itemkey}`]));
                        }
                        setSortKey({ key: itemkey, sort: "up" })
                    }
                }
            } else {
                if (itemkey == "gako_code") {
                    tempSearchData.sort((a, b) => a[`${itemkey}`] - b[`${itemkey}`]);
                } else {
                    tempSearchData.sort((a, b) => a[`${itemkey}`].toString().localeCompare(b[`${itemkey}`]));
                }
                setSortKey({ key: itemkey, sort: "up" })
            }


            if (currentPage === 1) {
                tempShowData = tempSearchData.slice(0, endIndex)
            } else {
                tempShowData = tempSearchData.slice(startIndex, endIndex)
            }
            setSearchData({ ...tempSearchData })
            setShowData({ ...tempShowData })
        }
    };
    const handleChange = (e, p) => {
        setCurrentPage(p)
    };
    useEffect(() => {
        if (searchData) {
            if (currentPage === 1) {
                setShowData(searchData.slice(0, endIndex))
            } else {
                setShowData(searchData.slice(startIndex, endIndex))
            }
        }
    }, [currentPage])

    const getGakoAutoList = () => {
        let list = []
        masterData?.gako_info?.map((item, index) => {
            if (item.homonJissekiFuragu == "1") {
                list.push({ "label": item.gakoMeisho, "value": item.gakoCd })

            }
        })
        return list;
    };

    // useEffect(() => {
    //     const s1 = document.getElementById("s1");
    //     const s2 = document.getElementById("s2");
    //     const s3 = document.getElementById("s3");
    //     s1.addEventListener("scroll", () => {
    //         s2.scrollTop = s1.scrollTop;
    //     });

    //     s2.addEventListener("scroll", () => {
    //         s1.scrollTop = s2.scrollTop;
    //         s3.scrollLeft = s2.scrollLeft;
    //     });
    //     s3.addEventListener("scroll", () => {
    //         s2.scrollLeft = s3.scrollLeft;
    //     });
    // }, [])

    useEffect(() => {
        const observer = new ResizeObserver(entries => {
            for (let entry of entries) {
                const { target, contentRect } = entry;
                setHeight(getDivHeightById('s2'))
            }
        });
        const targetDiv = document.getElementById('s2');
        observer.observe(targetDiv);
    }, [])

    const handleExport = () => {
        if (searchData.length > 0) {
            const fields = headerList.map(header => header.key);
            const headers = headerList.map(header => header.name);

            const csvData = searchData.map(row => {
                let rowData = fields.map(field => row[field] !== undefined ? row[field] : '');
                return rowData;
            });

            const csv = Papa.unparse({
                fields: headers,
                data: csvData
            });
            const shiftJISData = iconv.encode(csv, 'Shift_JIS');

            const blob = new Blob([shiftJISData], { type: 'text/csv;charset=shift-jis;' });
            saveAs(blob, '支援内容検索結果.csv');
        }

    };

    return (
        <div className={classes.ground} style={{ display: "flex", flexDirection: "column" }}>
            <div className={classes.itemMonthlyHead} style={{ borderLeft: '5px solid #2195D3', fontWeight: 'bold' }}>
                {`支援内容検索（${searchData.length}件 表示 ）`}
            </div>
            <Common_errorAlert
                open={errorOpen}
                setRrrorOpen={setRrrorOpen}
                errorContent={errorContent}
                border
                marginTop={"24px"}
            />
            <div style={{ display: "flex", marginTop: "16px" }}>
                <div className={classes.item}>
                    <div className={classes.itemText} style={{ width: "100px", height: "42px" }}>
                        サポーター名
                    </div>
                    <div style={{ padding: "8px", backgroundColor: "#ffffff", height: "42px" }}>
                        <Input
                            style={{ height: "26px", width: "144px", border: "1px solid #E0E0E0", borderRadius: "4px" }}
                            value={userName || ""}
                            onChange={(event) => setUserName(event.target.value)}
                            placeholder="サポーター名"
                        />
                    </div>
                    <div className={classes.itemText} style={{ width: "58px", height: "42px" }}>
                        学校名
                    </div>
                    <div className={classes.itemInput} style={{ width: "263px", height: "42px" }}>
                        <Autocomplete
                            className={classes.autocomplete}
                            disablePortal
                            options={getGakoAutoList()}
                            sx={{ width: 247 }}
                            value={gakoCode || ""}
                            onChange={(event: any, newValue: string | null) => {
                                setGakoCode(newValue != null ? newValue.label : "");
                            }}
                            renderInput={(params) => <TextField {...params} size="small" placeholder="学校名"
                            />}
                        />

                    </div>
                    <div className={classes.itemText} style={{ width: "72px", height: "42px" }}>
                        支援区分
                    </div>
                    <div className={classes.itemInput} style={{ width: "200px", height: "42px" }}>
                        <Select
                            className={classes.select}
                            style={{
                                width: "184px", height: "26px", borderRadius: "4px", position: "relative"

                            }}
                            sx={{ "& .MuiSelect-iconOutlined": { display: sienkubun_code ? 'none' : '' } }}
                            endAdornment={<ClearIcon sx={{ display: sienkubun_code ? "" : "none", width: "15px", height: "15px", padding: 0, margin: 0 }} onClick={() => setSienkubun_code("")}></ClearIcon>}
                            value={sienkubun_code || ""}
                            label=""
                            onChange={(event) => setSienkubun_code(event.target.value)}
                            displayEmpty
                            renderValue={
                                sienkubun_code !== null &&
                                    sienkubun_code !== "" ? undefined : () => <div style={{ color: "#9E9E9E", fontSize: "14px" }}>支援区分</div>
                            }
                        >
                            {support_kubunOption.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", marginTop: "16px" }}>
                <div className={classes.item}>
                    <div className={classes.itemText} style={{ width: "44px", height: "42px" }}>教科</div>
                    <div className={classes.itemInput} style={{ width: "200px", height: "42px" }}>
                        <Select
                            className={classes.select}
                            style={{
                                width: "184px", height: "26px", borderRadius: "4px", position: "relative"
                            }}
                            value={kyoka_code}
                            sx={{ "& .MuiSelect-iconOutlined": { display: kyoka_code ? 'none' : '' } }}
                            endAdornment={<ClearIcon sx={{ display: kyoka_code ? "" : "none", width: "15px", height: "15px", padding: 0, margin: 0 }} onClick={() => setKyoka_code("")}></ClearIcon>}
                            label=""
                            onChange={(event) => setKyoka_code(event.target.value)}
                            displayEmpty
                            renderValue={
                                kyoka_code !== null &&
                                    kyoka_code !== "" ? undefined : () => <div style={{ color: "#9E9E9E", fontSize: "14px" }}>教科</div>
                            }
                        >
                            {
                                subjectList.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.kyoka}>{item.kyoka}
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </div>
                    <div className={classes.itemText} style={{ width: "67px", height: "42px" }}>ICT環境</div>
                    <div className={classes.itemInput} style={{ width: "200px", height: "42px" }}>
                        <Select
                            className={classes.select}
                            style={{
                                width: "184px", height: "26px", borderRadius: "4px", position: "relative"
                            }}
                            value={ict_kankyo_code}
                            sx={{ "& .MuiSelect-iconOutlined": { display: ict_kankyo_code ? 'none' : '' } }}
                            endAdornment={<ClearIcon sx={{ display: ict_kankyo_code ? "" : "none", width: "15px", height: "15px", padding: 0, margin: 0 }} onClick={() => setIct_kankyo_code([])}></ClearIcon>}
                            label=""
                            multiple
                            onChange={(event) => setIct_kankyo_code(event.target.value)}
                            displayEmpty
                            renderValue={(selected) =>
                                ict_kankyo_code.length > 0
                                    ? selected.join(", ")
                                    : <div style={{ color: "#9E9E9E", fontSize: "14px" }}>ICT環境</div>
                            }
                        >
                            {
                                ictList.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.ictKankyou}>
                                            <Checkbox
                                                checked={ict_kankyo_code.includes(item.ictKankyou)}
                                                onChange={e => { }}
                                                value=""
                                            />
                                            {item.ictKankyou}
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </div>
                    <div className={classes.itemText} style={{ width: "100px", height: "42px" }}>ソフトウェア</div>
                    <div className={classes.itemInput} style={{ width: "200px", height: "42px" }}>
                        <Select
                            className={classes.select}
                            style={{
                                width: "184px", height: "26px", borderRadius: "4px", position: "relative"
                            }}
                            value={software_code}
                            sx={{ "& .MuiSelect-iconOutlined": { display: software_code ? 'none' : '' } }}
                            endAdornment={<ClearIcon sx={{ display: software_code ? "" : "none", width: "15px", height: "15px", padding: 0, margin: 0 }} onClick={() => setSoftware_code([])}></ClearIcon>}
                            label=""
                            multiple
                            onChange={(event) => setSoftware_code(event.target.value)}
                            displayEmpty
                            renderValue={(selected) =>
                                software_code.length > 0
                                    ? selected.join(", ")
                                    : <div style={{ color: "#9E9E9E", fontSize: "14px" }}>ソフトウェア</div>
                            }
                        >
                            {
                                softWareList.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.software}>
                                            <Checkbox
                                                checked={software_code.includes(item.software)}
                                                onChange={e => { }}
                                                value=""
                                            />
                                            {item.software}
                                        </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", marginTop: "16px" }}>
                <div className={classes.itemInput} style={{ width: "180px", height: "42px", backgroundColor: "transparent", padding: 0 }}>
                    <DatePicker
                        suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                        allowClear={true}
                        clearIcon={
                            <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                        }
                        style={{ fontSize: "14px", width: "180px", height: "42px" }}
                        onChange={changeFormDateValue}
                        value={formDate}
                        placeholder='訪問日 絞り込み'
                        format="YYYY/M/D"

                    />
                </div>
                <div style={{ alignItems: 'center', textAlign: "center", width: "32px", height: "42px", paddingLeft: 8 }}>
                    <span style={{ fontSize: "30px" }}>~</span>
                </div>
                <div className={classes.itemInput} style={{ width: "180px", height: "42px", backgroundColor: "transparent" }}>
                    <DatePicker
                        suffixIcon={<CalendarToday style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CalendarToday>}
                        allowClear={true}
                        clearIcon={
                            <CloseRounded style={{ width: VW(24), height: VH(24), color: "#27303D" }}></CloseRounded>
                        }
                        style={{ fontSize: "14px", width: "180px", height: "42px" }}
                        onChange={changeToDateValue}
                        value={toDate}
                        placeholder='訪問日 絞り込み'
                        format="YYYY/M/D"
                    />
                </div>
                <div style={{ paddingLeft: "24px" }}>
                    <Input
                        style={{ height: "42px", width: "396px", borderRadius: "4px 0px 0px 4px" }}
                        value={keyword || ""}
                        onChange={(event) => setKeyword(event.target.value)}
                        placeholder="検索キーワード"
                    />
                </div>
                <div onClick={getListData} style={{ borderRadius: "0px 4px 4px 0px", width: "47px", height: "41px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#1D3C70", cursor: "pointer" }}>
                    <SearchRounded style={{ color: "#ffffff", width: "24px", height: "24px" }}></SearchRounded>
                </div>
                <div onClick={handleExport} style={{ borderRadius: "4px", width: "50px", height: "42px", display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "#ffffff", border: "1px solid #2095D4", marginLeft: "8px", cursor: "pointer" }}>
                    <FileDownloadRounded style={{ color: "#2095D4", width: "24px", height: "24px" }}></FileDownloadRounded>
                </div>
            </div>
            <div className={classes.tableContainer} style={{ marginTop: "24px", }}>
                <table>
                    <thead>
                        <tr>
                            <th style={{ position: 'sticky', top: '0', zIndex: '1000', padding: 0 }}>
                                <div style={{ display: "flex" }}>
                                    <div className={classes.fixedColumn2FixedHeader} style={{ position: 'sticky', left: '0px', top: '0', zIndex: '1000' }}>
                                        <div style={{ backgroundColor: "#27303D", width: '40px', height: "40px", display: "flex", alignItems: "center", justifyContent: "center", color: "#fff", fontWeight: 700, fontSize: "14px", borderRight: "1px solid #E0E0E0", }}>
                                            No.
                                        </div>
                                    </div>
                                    <div id="s3" style={{ marginRight: "20px", display: "flex", overflowX: showData.length > 0 ? "hidden" : "auto", minHeight: "40px", paddingRight: 8 }}>
                                        {headerList.map((item, headerIndex) => {
                                            return (
                                                <div key={headerIndex} style={{ minHeight: "40px" }}>
                                                    <div onClick={() => onSortChange(item.key)} style={{ backgroundColor: "#27303D", minWidth: item.width1, width: item.width1, minHeight: "40px", display: "flex", alignItems: "center", justifyContent: "center", borderRight: "1px solid #E0E0E0", color: "#fff", fontWeight: 700, fontSize: "14px" }}>
                                                        {item.name}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <div className={classes.scrollbar} style={{ display: "flex", maxHeight: "100%", maxWidth: "100%", width: "100%" }}>
                            <div className={classes.fixedColumn2} id="s1" style={{ height: divHeight }}>
                                {showData && showData.map((item, listIndex) => {
                                    return (
                                        <div key={listIndex} style={{ backgroundColor: "#ffffff", width: "40px", height: "84px", display: "flex", alignItems: "center", justifyContent: "", color: "#27303D", fontWeight: 400, fontSize: "14px", borderBottom: "1px solid #E0E0E0", paddingLeft: "8px", borderRight: "1px solid #E0E0E0", }}>
                                            {item.no}
                                        </div>
                                    )
                                })}
                            </div>
                            <div id="right" style={{ display: "flex", flexDirection: "column", flex: 1, maxWidth: "100%", width: "100%" }}>
                                <div id="s2" style={{}}>
                                    {showData && showData.map((item, listIndex) => {
                                        let backgroundColor = (listIndex + 1) % 2 === 1 ? "#FFFFFF" : "#FFFFFF"
                                        return (
                                            <div key={listIndex} style={{ display: "flex" }}>
                                                {headerList.map((row, headerIndex) => {
                                                    let itemborderLeft = headerIndex === 0 ? "1px solid #E0E0E0" : "0px solid #E0E0E0"
                                                    return (
                                                        <div key={headerIndex}>
                                                            <div style={{ backgroundColor: backgroundColor, minWidth: row.width1, width: row.width1, height: "84px", display: "flex", borderRight: "1px solid #E0E0E0", color: "#27303D", fontWeight: 400, fontSize: "14px", borderBottom: "1px solid #E0E0E0", paddingLeft: row.checkBox ? 0 : "8px", alignItems: "center" }}>
                                                                <div style={{
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    display: headerIndex === 2 ? "-webkit-box" : "flex",
                                                                    alignItems: "center",
                                                                    WebkitLineClamp: 3,
                                                                    WebkitBoxOrient: 'vertical',
                                                                    lineHeight: '1.5em',
                                                                    height: '4.5em',
                                                                    wordBreak: "break-all"
                                                                }}>

                                                                    {item[row.key]}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div >
                    </tbody>
                </table>
            </div>
            {/* <div className={classes.scrollbar} style={{ display: "flex", maxHeight: "100%", overflowY: "hidden", maxWidth: "100%", width: "100%" }}>
                <div id="left" style={{ maxWidth: "100%", borderLeft: "1px solid #E0E0E0", }}>
                    <div style={{ marginTop: "24px", backgroundColor: "#27303D", width: '40px', height: "40px", display: "flex", alignItems: "center", justifyContent: "center", color: "#fff", fontWeight: 700, fontSize: "14px", borderRight: "1px solid #E0E0E0", }}>
                        No.
                    </div>

                </div>
                <div id="s3" style={{ marginRight: "20px", marginTop: "24px", display: "flex", overflowX: showData.length > 0 ? "hidden" : "auto", minHeight: "40px", paddingRight: 8 }}>
                    {headerList.map((item, headerIndex) => {
                        return (
                            <div key={headerIndex} style={{ minHeight: "40px" }}>
                                <div onClick={() => onSortChange(item.key)} style={{ backgroundColor: "#27303D", minWidth: item.width1, width: item.width1, minHeight: "40px", display: "flex", alignItems: "center", justifyContent: "center", borderRight: "1px solid #E0E0E0", color: "#fff", fontWeight: 700, fontSize: "14px" }}>
                                    {item.name}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div id="right" style={{ display: "flex", flex: 1, overflowY: "hidden", maxWidth: "100%", width: "100%" }}>
                    <div id="s1" className="scroll_sync" style={{ overflow: "hidden", height: divHeight }}>
                        {showData && showData.map((item, listIndex) => {
                            return (
                                <div key={listIndex} style={{ backgroundColor: "#ffffff", width: "40px", height: "84px", display: "flex", alignItems: "center", justifyContent: "", color: "#27303D", fontWeight: 400, fontSize: "14px", borderBottom: "1px solid #E0E0E0", paddingLeft: "8px", borderRight: "1px solid #E0E0E0", }}>
                                    {item.no}
                                </div>
                            )
                        })}
                    </div>

                    <div id="s2" style={{ overflowX: "auto", }}>
                        {showData && showData.map((item, listIndex) => {
                            let backgroundColor = (listIndex + 1) % 2 === 1 ? "#FFFFFF" : "#FFFFFF"
                            return (
                                <div key={listIndex} style={{ display: "flex" }}>
                                    {headerList.map((row, headerIndex) => {
                                        let itemborderLeft = headerIndex === 0 ? "1px solid #E0E0E0" : "0px solid #E0E0E0"
                                        return (
                                            <div key={headerIndex}>
                                                <div style={{ backgroundColor: backgroundColor, minWidth: row.width1, width: row.width1, height: "84px", display: "flex", borderRight: "1px solid #E0E0E0", color: "#27303D", fontWeight: 400, fontSize: "14px", borderBottom: "1px solid #E0E0E0", paddingLeft: row.checkBox ? 0 : "8px" }}>
                                                    <div style={{
                                                        width: '100%', height: '100%', overflow: 'hidden',
                                                        textOverflow: 'ellipsis', display: headerIndex === headerList.length - 1 ? "block" : "flex", overflowWrap: 'break-word', alignItems: headerIndex === headerList.length - 1 ? "" : "center"
                                                    }}>
                                                        {item[row.key]}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div > */}
            <Pagination
                page={currentPage}
                className={classes.page}
                count={searchData ? Math.ceil(searchData?.length / 100) : 0}
                color="buttonBLue"
                onChange={handleChange}
                style={{
                    display: "flex", justifyContent: "center", marginBottom: 8, marginTop: 8
                }}
            />
        </div >
    )
}

SupportContents.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(SupportContents);