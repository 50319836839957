import React, { useEffect, useState, useRef } from "react";
import { withStyles, makeStyles } from "@mui/styles";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { VW, VH } from "../../componets/function/sizeAdapter"
import sortIcon from "../../componets/icon/sortIcon.svg"
import visitIcon from "../../componets/icon/visitIcon.svg"
import infoIcon from "../../componets/icon/infoIcon.svg"
import deleteIcon from "../../componets/icon/deleteIcon.svg"
import { KeyboardArrowUpRounded, KeyboardArrowDownRounded } from "@mui/icons-material";
import { Box, MenuItem, Select, List, ListItemButton, Collapse } from "@mui/material";
import { Button } from 'antd';
import Input from "antd/es/input/Input";
import SupportDialog from "../../componets/dialog/SupportDialog";
import HelpDialog from "../../componets/dialog/HelpDialog";
import log from "../../componets/function/log";
import { teiji_jikanOption, gyomu_shuruiOption, jikangaiOption, tochaku_Option } from "../../wordsFile/selectItem";
import RouteDialog from "../../componets/dialog/RouteDialog";
import ClearIcon from "@mui/icons-material/Clear";
import CommonDialog from "../../componets/dialog/CommonDialog";
import InsertDialog from "../../componets/dialog/InsertDialog";
const styles = (theme) => ({
    bar: {
        display: "flex",
        alignItems: "center",
        height: VH(64),
        backgroundColor: "#ffffff",
        justifyContent: 'space-between',
        paddingLeft: VW(16),
        paddingRight: VW(16)
    },
    icon: {
        width: VW(32),
        height: VH(32),
        marginRight: VW(24)
    },
    detailTable: {
        backgroundColor: "#ffffff",
        paddingBottom: VH(24),

    },
    item: {
        display: "flex",
        alignItems: "center",
        marginLeft: VW(24),
        marginRight: VW(24),
        borderTop: '1px solid #E0E0E0',
    },
    itemLable: {
        width: "33.333%",
        display: "flex",
        alignItems: "center",
    },
    itemText: {
        color: "#27303D",
        fontSize: VW(12),
        fontWeight: 700,
        // width: VW(80),
        width: "30.3%",
        height: VH(72),
        backgroundColor: "#E9F4FB",
        display: "flex",
        alignItems: "center",
        padding: VW(8),
    },
    itemInput: {
        // width: VW(184),
        width: "69.7%",
        height: VH(72),
        backgroundColor: "#ffffff",
        display: "flex",
        alignItems: "center",
        padding: VW(8),
        fontSize: VW(16),
    },
    select: {
        "&:hover": {
            "&& fieldset": {
                border: "1px solid #4096ff"
            }
        },
        "&.MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
                border: "1px solid #4096ff"
            }
        }
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center",
        '& > Button': {
            width: VW(97),
            height: VH(40),
            borderRadius: VW(4),
            fontSize: VW(12),
            fontWeight: 700,
            backgroundColor: "#ffffff",
            color: "#2195D3",
            border: `1px solid #2195D3`,
        }
    },
    listButton: {
        backgroundColor: "#ffffff",
        width: VW(129),
        height: VH(40),
        fontWeight: 400,
        fontSize: VW(14),

    },
});

function VisitView(props) {
    const {
        classes,
        visitData,
        setVisitData,
        index,
        item,
        updateVisitData,
        masterData,
        updateVisitSupportData,
        deleteVisitData,
        updateVisitRouteData,
        startOfuku,
        endOfuku,
        setStartOfuku,
        setEndOfuku,
        userID,
        menuFlag

    } = props



    const usePlaceholderStyles = makeStyles(theme => ({
        placeholder: {
            color: "#9E9E9E"
        }
    }));

    const Placeholder = ({ children }) => {
        const classes = usePlaceholderStyles();
        return <div className={classes.placeholder}>{children}</div>;
    };

    const [supportOpen, setSupportOpen] = useState(false)
    const supportOnClose = () => {
        setSupportOpen(false)
    }
    const supportOnOpen = () => {
        setSupportOpen(true)
    }
    const [routeOpen, setRouteOpen] = useState(false)
    const routeOnClose = () => {
        setRouteOpen(false)
    }
    const routeOnOpen = () => {
        setRouteOpen(true)
    }
    const [helpOpen, setHelpOpen] = useState(false)
    const helpOnClose = () => {
        setHelpOpen(false)
    }
    const helpOnOpen = () => {
        setHelpOpen(true)
    }
    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open);
    }
    const [commonOpen, setCommonOpen] = useState(false);
    const commonDialogOnOpen = () => {
        setOpen(false);
        setCommonOpen(true);
    }
    const commonDialogOnClose = () => {
        setCommonOpen(false);
    }
    const commonDialogOnSelect = (item) => {
        visitData.homon_jiseki[index].kotsukikanList = item.kotsukikanList
        visitData.homon_jiseki[index].gyomu_shurui = item.gyomu_shurui
        visitData.homon_jiseki[index].homon_saki = item.homon_saki
        visitData.homon_jiseki[index].jigyo_kubun = item.jigyo_kubun
        visitData.homon_jiseki[index].sagyo_basho = item.sagyo_basho
        visitData.homon_jiseki[index].sagyo_basho_sonota = item.sagyo_basho_sonota
        visitData.homon_jiseki[index].sagyo_naiyo = item.sagyo_naiyo
        visitData.homon_jiseki[index].user_id = userID
        visitData.homon_jiseki[index].homon_shuppatsu = item.homon_shuppatsu
        visitData.homon_jiseki[index].homon_tochaku = item.homon_tochaku
        visitData.homon_jiseki[index].homon_shuppatsu_sonota = item.homon_shuppatsu_sonota
        visitData.homon_jiseki[index].homon_tochaku_sonota = item.homon_tochaku_sonota
        setVisitData({ ...visitData })
        setCommonOpen(false);
    }
    const [insertOpen, setInsertOpen] = useState(false);
    const insertDialogOnOpen = () => {
        setOpen(false);
        setInsertOpen(true);
    }
    const insertDialogOnClose = () => {
        setInsertOpen(false);
    }
    // useEffect(() => {
    //     if (item.kyuka_kubun == "1" || item.kyuka_kubun == "4" || item.gyomu_shurui == "2" &&
    //         item.sagyo_basho != "3" &&
    //         item.sagyo_basho_sonota != "GIGAヘルプデスク") {
    //         updateVisitData(null, index, 'teiji_jikan')
    //     }
    // }, [visitData])
    return (
        <div style={{ boxShadow: '0px 0.12vw 0.85vh 0.12vw #DDD' }}>
            <div style={{ marginTop: VH(8), width: "100%" }}>
                <div className={classes.bar} >
                    <div style={{
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <img
                            src={visitIcon}
                            className={classes.icon}
                            style={{ marginRight: VW(8) }}
                        />

                        <div
                            style={{
                                color: "#053550",
                                fontWeight: 700,
                                fontSize: VW(16)
                            }}>{item.name}</div>
                        <List style={{ height: VH(40), padding: 0, backgroundColor: "#fff", borderRadius: "4px", marginLeft: VW(20), border: "1px solid #2095D4", display: item.kyuka_kubun == "1" || item.kyuka_kubun == "4" ? "none" : "" }}>
                            <ListItemButton style={{ display: "flex", justifyContent: 'center', position: 'relative', height: VH(40), width: VW(129) }} onClick={handleClick}>
                                <span style={{ fontSize: VW(12), color: "#2095D4", fontWeight: 700 }}>{"よく行く訪問先"}</span>
                            </ListItemButton>
                            <Collapse in={open} timeout="auto" unmountOnExit style={{ position: 'absolute', width: '100%', zIndex: '9999' }}>
                                <List component="div" disablePadding>
                                    <Button
                                        className={classes.listButton} style={{ borderRadius: "4px 4px 0px 0px" }}
                                        onClick={commonDialogOnOpen}
                                    >一覧から選択</Button>
                                </List>
                                <List component="div" disablePadding>
                                    <Button
                                        className={classes.listButton} style={{ borderRadius: "0px 0px 4px 4px", borderTop: "0px" }}
                                        onClick={insertDialogOnOpen}
                                    >入力内容を登録</Button>
                                </List>
                            </Collapse>
                        </List>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className={classes.buttonGroup} style={{ marginRight: VW(8) }}>
                            <Button onClick={routeOnOpen} disabled={(item.kyuka_kubun == "1" || item.kyuka_kubun == "4" || item.sagyo_basho == "1" || item.sagyo_basho_sonota == "自宅") ? true : false}>経路</Button>
                        </div>
                        <div className={classes.buttonGroup}>
                            <Button onClick={supportOnOpen} disabled={(item.kyuka_kubun == "1" || item.kyuka_kubun == "4" || item.gyomu_shurui == "2" || item.jigyo_kubun == "" || item.jigyo_kubun == null) ? true : false}>支援内容</Button>
                        </div>
                        <img
                            style={{ width: VW(24), height: VH(24), marginLeft: VW(8) }}
                            src={deleteIcon}
                            onClick={() => { deleteVisitData(index) }}
                        />
                    </div>
                </div>
                <div className={classes.detailTable}>
                    <div className={classes.item}>
                        <div className={classes.itemLable}>
                            <div className={classes.itemText}>業務の種類
                                {/* <img
                                style={{ width: VW(20), height: VH(20), marginLeft: VW(8) }}
                                src={infoIcon}
                                onClick={() => helpOnOpen()}
                            /> */}
                            </div>
                            <div className={classes.itemInput}>
                                <Select
                                    className={classes.select}
                                    style={{
                                        width: "100%", height: VH(56), borderRadius: VW(6), backgroundColor: item.kyuka_kubun == "1" || item.kyuka_kubun == "4" ? "rgba(0, 0, 0, 0.04)" : "#fff"

                                    }}
                                    sx={{ fontSize: VW(12) }}
                                    value={item.gyomu_shurui || ""}
                                    label=""
                                    onChange={(event) => updateVisitData(event.target.value, index, 'gyomu_shurui')}
                                    displayEmpty
                                    disabled={(item.kyuka_kubun == "1" || item.kyuka_kubun == "4") ? true : false}
                                    renderValue={
                                        item.gyomu_shurui !== null &&
                                            item.gyomu_shurui !== "" ? undefined : () => <Placeholder>-</Placeholder>
                                    }
                                >
                                    {gyomu_shuruiOption.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </div>
                        </div>
                        <div className={classes.itemLable}>
                            <div className={classes.itemText}>対象校</div>
                            <div className={classes.itemInput}>
                                <Select
                                    className={classes.select}
                                    style={{
                                        width: "100%", height: VH(56), borderRadius: VW(6), backgroundColor: item.kyuka_kubun == "1" || item.kyuka_kubun == "4" || item.gyomu_shurui == "2" || item.gyomu_shurui == "" || item.gyomu_shurui == null ? "rgba(0, 0, 0, 0.04)" : "#fff"

                                    }}
                                    sx={{ fontSize: VW(12) }}
                                    value={item.homon_saki || ""}
                                    label=""
                                    onChange={(event) => updateVisitData(event.target.value, index, 'homon_saki')}
                                    displayEmpty
                                    disabled={item.kyuka_kubun == "1" || item.kyuka_kubun == "4" || item.gyomu_shurui == "2" || item.gyomu_shurui == "" || item.gyomu_shurui == null ? true : false}
                                    renderValue={
                                        item.homon_saki !== null &&
                                            item.homon_saki !== "" ? undefined : () => <Placeholder>-</Placeholder>
                                    }
                                >
                                    {masterData.gako_info.map((item, i) => {
                                        if (item.homonJissekiFuragu === "1") {
                                            return (
                                                <MenuItem key={i} value={item.no}>{item.gakoMeisho}</MenuItem>
                                            )
                                        } else {
                                            return null
                                        }

                                        // var jigyo_kubunlabel = ""
                                        // masterData.jigyoKubunOptionList.map((item) => {
                                        //     if (item.value == visitData?.homon_jiseki[index]?.jigyo_kubun) {
                                        //         jigyo_kubunlabel = item.label
                                        //     }
                                        // })
                                        // if ((item.jigyoKubun != "" && jigyo_kubunlabel === item.jigyoKubun)) {

                                        //     return (
                                        //         <MenuItem key={i} value={item.no}>{item.gakoMeisho}</MenuItem>
                                        //     )
                                        // } else {
                                        //     return null
                                        // }
                                    })}
                                </Select>
                            </div>
                        </div>
                        <div className={classes.itemLable}>
                            <div className={classes.itemText}>事業区分</div>
                            <div className={classes.itemInput}>
                                <Select
                                    className={classes.select}
                                    style={{
                                        width: "100%", height: VH(56), borderRadius: VW(6), backgroundColor: item.kyuka_kubun == "1" || item.kyuka_kubun == "4" || item.gyomu_shurui == "1" ? "rgba(0, 0, 0, 0.04)" : "#fff"

                                    }}
                                    sx={{ fontSize: VW(12) }}
                                    value={item.jigyo_kubun || ""}
                                    label=""
                                    onChange={(event) => updateVisitData(event.target.value, index, 'jigyo_kubun')}
                                    displayEmpty
                                    disabled={item.kyuka_kubun == "1" || item.kyuka_kubun == "4" || item.gyomu_shurui == "1" ? true : false}
                                    renderValue={
                                        item.jigyo_kubun !== null &&
                                            item.jigyo_kubun !== "" ? undefined : () => <Placeholder>-</Placeholder>
                                    }
                                >
                                    {masterData.jigyoKubunOptionList.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </div>
                        </div>
                    </div>

                    <div className={classes.item} >
                        <div className={classes.itemLable}>
                            <div className={classes.itemText} >作業場所</div>
                            <div className={classes.itemInput}>
                                <Select
                                    className={classes.select}
                                    style={{
                                        width: "100%", height: VH(56), borderRadius: VW(6), backgroundColor: item.kyuka_kubun == "1" || item.kyuka_kubun == "4" ? "rgba(0, 0, 0, 0.04)" : "#fff"

                                    }}
                                    sx={{ "& .MuiSelect-iconOutlined": { display: item.sagyo_basho ? 'none' : '' }, fontSize: VW(12) }}
                                    endAdornment={<ClearIcon sx={{ display: item.sagyo_basho ? "" : "none", width: VW(15), height: VW(15) }} onClick={() => updateVisitData("", index, 'sagyo_basho')}></ClearIcon>}
                                    value={item.sagyo_basho || ""}
                                    label=""
                                    onChange={(event) => updateVisitData(event.target.value, index, 'sagyo_basho')}
                                    displayEmpty
                                    disabled={(item.kyuka_kubun == "1" || item.kyuka_kubun == "4") ? true : false}
                                    renderValue={
                                        item.sagyo_basho !== null &&
                                            item.sagyo_basho !== "" ? undefined : () => <Placeholder>-</Placeholder>
                                    }
                                >
                                    {masterData.sagyobasyo_info.map((item, i) => {
                                        return (
                                            <MenuItem key={i} value={item.no}>{item.homonsakiMeisho}</MenuItem>
                                        )
                                        // var jigyo_kubunlabel = ""
                                        // masterData.jigyoKubunOptionList.map((item) => {
                                        //     if (item.value == visitData?.homon_jiseki[index]?.jigyo_kubun) {
                                        //         jigyo_kubunlabel = item.label
                                        //     }
                                        // })
                                        // if ((jigyo_kubunlabel === item.jigyoKubun) || item.homonsakiMeisho === "その他") {

                                        //     return (
                                        //         <MenuItem key={i} value={item.no}>{item.homonsakiMeisho}</MenuItem>
                                        //     )
                                        // } else {
                                        //     return null
                                        // }
                                    })}
                                </Select>


                            </div>
                        </div>
                        <div className={classes.itemLable}>
                            <div style={{ width: "100%", marginRight: VW(19) }} >
                                <Input
                                    style={{ height: VH(56) }}
                                    value={item.sagyo_basho_sonota || ""}
                                    onChange={(event) => updateVisitData(event.target.value, index, 'sagyo_basho_sonota')}
                                    disabled={visitData?.homon_jiseki[index]?.sagyo_basho === "0" && item.kyuka_kubun != "1" && item.kyuka_kubun != "4" ? false : true}
                                    maxLength={60}
                                    placeholder="その他の場所を入力してください"
                                />
                            </div>
                        </div>
                        <div className={classes.itemLable}>
                            <div className={classes.itemText}>作業内容</div>
                            <div className={classes.itemInput}>
                                <Input
                                    style={{ width: "100%", height: VH(56) }}
                                    value={item.sagyo_naiyo || ""}
                                    onChange={(event) => updateVisitData(event.target.value, index, 'sagyo_naiyo')}
                                    maxLength={60}
                                    disabled={(item.kyuka_kubun == "1" || item.kyuka_kubun == "4") ? true : false}
                                    placeholder="作業内容を入力してください"
                                />
                            </div>
                        </div>
                    </div>

                    <div className={classes.item} >
                        <div className={classes.itemLable}>
                            <div className={classes.itemText} style={{ borderBottom: '1px solid #E0E0E0', }}>訪問区分</div>
                            <div className={classes.itemInput} style={{ borderBottom: '1px solid #E0E0E0', }}>
                                <Select
                                    className={classes.select}
                                    style={{
                                        width: "100%", height: VH(56), borderRadius: VW(6),
                                        backgroundColor:
                                            item.kyuka_kubun == "1" ||
                                                item.kyuka_kubun == "4" ||
                                                item.gyomu_shurui == "2" &&
                                                item.sagyo_basho != "3" &&
                                                item.sagyo_basho_sonota != "GIGAヘルプデスク"
                                                ? "rgba(0, 0, 0, 0.04)" : "#fff"

                                    }}
                                    sx={{ fontSize: VW(12) }}
                                    value={item.teiji_jikan || ""}
                                    label=""
                                    onChange={(event) => updateVisitData(event.target.value, index, 'teiji_jikan')}
                                    displayEmpty
                                    disabled={item.kyuka_kubun == "1" || item.kyuka_kubun == "4" || item.gyomu_shurui == "2" &&
                                        item.sagyo_basho != "3" &&
                                        item.sagyo_basho_sonota != "GIGAヘルプデスク" ? true : false}
                                >
                                    {teiji_jikanOption.map((rows, index) => {
                                        if (item.kyuka_kubun == "2" || item.kyuka_kubun == "5") {  //AM
                                            if (rows.value == "1" || rows.value == "4") {
                                                return (
                                                    <MenuItem key={index} value={rows.value}>{rows.label}</MenuItem>
                                                )
                                            }

                                        } else if (item.kyuka_kubun == "3" || item.kyuka_kubun == "6") { //PM
                                            if (rows.value == "1" || rows.value == "3") {
                                                return (
                                                    <MenuItem key={index} value={rows.value}>{rows.label}</MenuItem>
                                                )
                                            }
                                        } else {
                                            return (
                                                <MenuItem key={index} value={rows.value}>{rows.label}</MenuItem>
                                            )
                                        }

                                    })}
                                </Select>
                            </div>
                        </div>
                        <div className={classes.itemLable}>
                            <div className={classes.itemText} style={{ borderBottom: '1px solid #E0E0E0', }}>時間外</div>
                            <div className={classes.itemInput} style={{ borderBottom: '1px solid #E0E0E0', }}>
                                <Select
                                    className={classes.select}
                                    style={{
                                        width: "100%", height: VH(56), borderRadius: VW(6), backgroundColor: item.kyuka_kubun == "1" || item.kyuka_kubun == "4" ? "rgba(0, 0, 0, 0.04)" : "#fff"

                                    }}
                                    sx={{ fontSize: VW(12) }}
                                    value={item.jikangai || ""}
                                    label=""
                                    onChange={(event) => updateVisitData(event.target.value, index, 'jikangai')}
                                    displayEmpty
                                    disabled={(item.kyuka_kubun == "1" || item.kyuka_kubun == "4") ? true : false}
                                    renderValue={
                                        item.jikangai !== null &&
                                            item.jikangai !== "" ? undefined : () => <Placeholder>-</Placeholder>
                                    }
                                >
                                    {jikangaiOption.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </div>
                        </div>
                        <div style={{
                            color: "#27303D",
                            fontSize: VW(12),
                            fontWeight: 400,
                            width: VW(80),
                            height: VH(72),
                            display: "flex",
                            alignItems: "center",
                        }}>時間</div>
                    </div>
                    {/* <div className={classes.item}>
                        <div className={classes.itemText}>出発地</div>
                        <div className={classes.itemInput}>
                            <div style={{ width: VW(168) }}>
                                {item.homon_shuppatsu || ""}

                            </div>
                        </div>
                    </div> */}

                    {/* {index === 0 ?
                        <div className={classes.item}>
                            <div className={classes.itemText}>到着地</div>
                            <div className={classes.itemInput}>
                                <Select
                                    className={classes.select}
                                    style={{
                                        width:"100%",  height: VH(56), borderRadius: VW(6)

                                    }}
                                    value={item.homon_tochaku || ""}
                                    label=""
                                    onChange={(event) => updateVisitData(event.target.value, index, 'homon_tochaku')}
                                    displayEmpty
                                    renderValue={
                                        item.homon_tochaku !== null &&
                                            item.homon_tochaku !== "" ? undefined : () => <Placeholder>-</Placeholder>
                                    }
                                >
                                    {tochaku_Option.map((item, index) => {
                                        return (
                                            <MenuItem key={index} value={item.label}>{item.label}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </div>
                        </div>
                        :
                        <div className={classes.item}>
                            <div className={classes.itemText}>到着地</div>
                            <div className={classes.itemInput}>
                                <div style={{ width: VW(168) }}>
                                    {item.homon_tochaku || ""}
                                </div>

                            </div>
                        </div>} */}




                </div>

            </div >
            <SupportDialog
                open={supportOpen}
                onClose={supportOnClose}
                visitData={visitData}
                setVisitData={setVisitData}
                index={index}
                item={item}
                updateVisitData={updateVisitData}
                masterData={masterData}
                updateVisitSupportData={updateVisitSupportData}
            />
            <RouteDialog
                open={routeOpen}
                onClose={routeOnClose}
                visitData={visitData}
                setVisitData={setVisitData}
                index={index}
                item={item}
                updateVisitData={updateVisitData}
                masterData={masterData}
                updateVisitRouteData={updateVisitRouteData}
                startOfuku={startOfuku}
                setStartOfuku={setStartOfuku}
                endOfuku={endOfuku}
                setEndOfuku={setEndOfuku}
            />
            <HelpDialog
                open={helpOpen}
                onClose={helpOnClose}
            />
            <CommonDialog
                open={commonOpen}
                onClose={commonDialogOnClose}
                onSelect={commonDialogOnSelect}
                masterData={masterData}
                visitData={visitData}
                visitDataIndex={index}
            />
            <InsertDialog
                open={insertOpen}
                onClose={insertDialogOnClose}
                masterData={masterData}
                data={JSON.parse(JSON.stringify(item))}
            />
        </div >
    )
}

VisitView.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(VisitView);