import React, { useEffect, useState, Fragment } from "react";
import {
    Button,
    TextField,
    IconButton,
    Alert
} from "@mui/material";
import log from "../componets/function/log";
import { withStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { routerNames } from '../wordsFile/wordsFile'
import { Visibility, VisibilityOff } from "@mui/icons-material"
import Common_errorAlert from "../componets/common/Common_errorAlert";
import { formatCheck, errorMessage, localstorage } from "../wordsFile/wordsFile";
import { ApiToken, ApiUserUserauthority } from '../network/api'
import qs from "qs"
import { VW, VH } from "../componets/function/sizeAdapter"
import logo from "../componets/icon/logo.svg"
import { getSession, getUserPool, signInWithEmail } from "../network/UserPool";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import ReactDOM from 'react-dom/client';
import CircularProgress from '@mui/material/CircularProgress';
import { ApiMasterInfo, ApiMenuAuthorityInfo } from "../network/api";
import { objectToString, stringToObejct } from "../componets/function/JsonUtil";
import dayjs from 'dayjs';

const styles = (theme) => ({
    groundBlock: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
    },
    LoginView: {
        width: VW(845),
        // height: VH(496),
        backgroundColor: '#FFF',
        boxShadow: '0px 0.26vw 1.05vh 0.26vw #EAEFF3',
        textAlign: 'center',
        paddingTop: VH(40),
        paddingBottom: VH(40),
        borderRadius: "8px",
        position: 'absolute',
        // top: VH(104)
    },
    tittleIcon: {
        width: '100%',
        textAlign: 'center',
        fontSize: VW(20),
        fontWeight: 'bold'
    },
    label: {
        width: '100%',
        display: "flex",
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(24)
    },
    text: {
        width: '100%',
        display: "flex",
        justifyContent: 'center',
        fontSize: VW(16)
    },
    loginButton: {
        backgroundColor: theme.palette.primary.main,
        marginTop: VH(24),
        fontSize: VW(16),
        fontWeight: 700
    },
    input: {
        height: VH(48),
        fontSize: VW(16),
    },
    inputContent: {
        padding: VH(14),
        fontSize: VW(16),
    },
    linkFont: {
        color: '#0086D0',
        marginTop: VH(24),
        marginBottom: VH(54),
        fontSize: VW(16),
        '& > span': {
            cursor: 'pointer'
        }
    },
    errorAlert: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: VH(24)
    }
});

function Login(props) {
    const {
        classes,
    } = props


    useEffect(() => {
        localStorage.removeItem(localstorage.access_token)
        localStorage.removeItem(localstorage.refresh_token)
        localStorage.removeItem(localstorage.id_token)
        localStorage.removeItem(localstorage.authority_id1)
        localStorage.removeItem(localstorage.authority_id2)
        localStorage.removeItem(localstorage.authority_id3)
        localStorage.removeItem(localstorage.authority_id4)
        localStorage.removeItem(localstorage.authority_id5)
        localStorage.removeItem(localstorage.preferred_username)
        localStorage.removeItem(localstorage.email)
        localStorage.removeItem(localstorage.username)
    }, [])



    // password display
    const [showPassword, setShowPassword] = useState(true);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };



    // login api
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const navigate = useNavigate()
    const login = () => {
        if (username === '') {
            setErrorContent(errorMessage.nameEmptyError)
            setRrrorOpen(true)
        } else if (!formatCheck.Email.test(username)) {
            setErrorContent(errorMessage.nameFormatError)
            setRrrorOpen(true)
        } else if (password === '') {
            setErrorContent(errorMessage.passwordNull)
            setRrrorOpen(true)
        } else {
            setRrrorOpen(false)
            signInWithEmail(username, password)
                .then((res) => {
                    console.log(res)
                    if (res.code == 'newPasswordRequired') {
                        navigate(routerNames.NewPassword, { state: { email: username } })
                    } else {
                        let data = res.data
                        if (data.idToken.payload['custom:validity_from'] && data.idToken.payload['custom:validity_to']) {
                            if (dayjs() > dayjs(data.idToken.payload['custom:validity_from']) &&
                                dayjs() < dayjs(data.idToken.payload['custom:validity_to'])) {
                                localStorage.setItem(localstorage.access_token, data.accessToken.jwtToken)
                                localStorage.setItem(localstorage.refresh_token, data.refreshToken.token)
                                localStorage.setItem(localstorage.id_token, data.idToken.jwtToken)
                                localStorage.setItem(localstorage.authority_id1, data.idToken.payload['custom:authority1'])
                                localStorage.setItem(localstorage.authority_id2, data.idToken.payload['custom:authority2'])
                                localStorage.setItem(localstorage.authority_id3, data.idToken.payload['custom:authority3'])
                                localStorage.setItem(localstorage.authority_id4, data.idToken.payload['custom:authority4'])
                                localStorage.setItem(localstorage.authority_id5, data.idToken.payload['custom:authority5'])
                                localStorage.setItem(localstorage.preferred_username, data.idToken.payload['custom:preferred_username'])
                                localStorage.setItem(localstorage.email, data.idToken.payload.email)
                                localStorage.setItem(localstorage.username, data.idToken.payload.sub)
                                localStorage.setItem(localstorage.loginDate, dayjs())
                                getMasterInfo()
                            } else {
                                setErrorContent(errorMessage.E0003)
                                setRrrorOpen(true)
                            }
                        } else {
                            setErrorContent(errorMessage.E0003)
                            setRrrorOpen(true)
                        }

                    }
                })
                .catch((err) => {
                    console.log(err)
                    if (err.message.includes('Incorrect username or password.')) {
                        setErrorContent(errorMessage.E0003)
                        setRrrorOpen(true)
                    } else {
                        setErrorContent(errorMessage.E0003)
                        setRrrorOpen(true)
                    }
                })
        }
    }
    const getMasterInfo = () => {
        let param = { user_id: localStorage.getItem(localstorage.username) }
        ApiMasterInfo(param).then(res => {
            let resData = res.data
            log(res)
            if (res.status === 200) {
                var i = 1
                // var str = ""
                var jigyoKubunOptionList = []
                for (let index = 0; index < resData.master_info.gako_info.length; index++) {
                    if (resData.master_info.gako_info[index].homonJissekiFuragu === "1") {
                        jigyoKubunOptionList.push({ "value": i.toString(), "label": resData.master_info.gako_info[index].jigyoKubun })
                        i += 1
                    }
                }
                jigyoKubunOptionList = jigyoKubunOptionList.filter((item, index, array) =>
                    array.findIndex((i) => i.label === item.label) === index
                );
                jigyoKubunOptionList.map((item, index) => {
                    item.value = index.toString()
                })
                resData.master_info["jigyoKubunOptionList"] = jigyoKubunOptionList
                resData.master_info.sagyobasyo_info.push({ "no": "0", "homonsakiMeisho": "その他" })
                localStorage.setItem(localstorage.masterData, objectToString(resData.master_info))
                getMenuInfo()


            } else {
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const getMenuInfo = () => {
        ApiMenuAuthorityInfo().then(res => {
            let resData = res.data
            log(res)
            if (res.status === 200) {
                let menuList = []
                let menuData = {}
                // let manager = true
                for (let index = 0; index < resData?.menu_Info?.length; index++) {
                    // if (resData.menu_Info[index].biko == "訪問実績入力") {
                    //     manager = false
                    // }
                    let menuAuthority = {
                        name: resData.menu_Info[index].biko,
                        link: resData.menu_Info[index].url,
                        selectColor: '#053550',
                        iconColor: '#AAAAAA',
                    }
                    menuList.push(menuAuthority)
                }
                menuData["data"] = menuList
                localStorage.setItem(localstorage.menuItem, objectToString(menuData))
                navigate(menuData?.data[0]?.link)
                // if (manager) {
                //     navigate(routerNames.TerminalManage)
                // } else {
                //     navigate(routerNames.VisitRecordInput)
                // }

            }
        }).catch(err => {
            console.log(err)
        })
    };
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')
    // to sendCode
    const toSendCode = () => {
        navigate(routerNames.SendCode)
    }

    return (
        <div className={classes.groundBlock}>
            <div className={classes.LoginView}>
                <img
                    src={logo}
                    style={{
                        width: VW(240),
                        height: VH(74),
                    }}
                />
                {/* <div className={classes.tittleIcon}>訪問実績入力システム</div> */}

                {errorOpen && <div className={classes.errorAlert}>
                    <div style={{ width: VW(400) }}>
                        <Common_errorAlert
                            open={errorOpen}
                            setRrrorOpen={setRrrorOpen}
                            errorContent={errorContent}
                        />
                    </div>
                </div>}
                <div className={classes.label} styles={{ marginTop: VH(24) }}>
                    <div style={{ width: VW(400), textAlign: 'left', fontSize: VW(14), fontWeight: 700, marginBottom: VH(8) }}>メールアドレス <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                </div>
                <div className={classes.text}>
                    <TextField
                        name="username"
                        autoComplete="username"
                        sx={{ width: VW(400) }}
                        placeholder="メールアドレスを入力してください"
                        onChange={(event) => { setUsername(event.target.value) }}
                        value={username}
                        inputProps={{ maxLength: 200 }}
                        InputProps={{
                            className: classes.input,
                            classes: {
                                input: classes.inputContent
                            }
                        }}
                    />
                </div>
                <div className={classes.label}>
                    <div style={{ width: VW(400), textAlign: 'left', fontSize: VW(14), fontWeight: 700, marginBottom: VH(8) }}>パスワード <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                </div>
                <div className={classes.text}>
                    <TextField
                        name="password"
                        autoComplete="password"
                        sx={{ width: VW(400) }}
                        type={showPassword ? 'password' : 'text'}
                        placeholder="パスワードを入力してください"
                        onChange={(event) => { setPassword(event.target.value) }}
                        value={password}
                        inputProps={{ maxLength: 200 }}
                        InputProps={{
                            className: classes.input,
                            endAdornment: (
                                <IconButton
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            ),
                            className: classes.input,
                            classes: {
                                input: classes.inputContent
                            }
                        }}
                    />
                </div>

                <Button
                    className={classes.loginButton}
                    variant="contained"
                    style={{ width: VW(400), height: VH(56) }}
                    onClick={login}
                >ログイン
                </Button>

                <div className={classes.linkFont}>
                    <span onClick={toSendCode}>パスワードをお忘れの場合</span>
                </div>
            </div>
        </div >
    )
}

Login.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Login);