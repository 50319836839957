import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button, Input, DatePicker, message } from 'antd';
import AddIcon from "../icon/AddIcon";
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import locale from "antd/es/date-picker/locale/ja_JP";
import { routerNames } from '../../wordsFile/wordsFile'
import { formatCheck, errorMessage, localstorage } from "../../wordsFile/wordsFile";
import { VW, VH } from "../function/sizeAdapter"
import { IconButton, Box } from "@mui/material";
import { Close } from "@mui/icons-material";
import SupportView from "./SupportView";
import { ApiHelpFile } from "../../network/api";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
// Import the worker as recommended by react-pdf documentation
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

dayjs.extend(utc)


const openHelpRecordInput = () => {
    alert('test')

}
const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        }
    },
    buttonGroup: {
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            borderRadius: "4px",
            width: VW(400),
            height: VH(50),
            fontSize: VW(16),
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& > Button:nth-child(2)': {
            color: '#FFF',
            backgroundColor: theme.palette.primary.main,
            marginLeft: VW(16)
        }
    },
    buttonGroup2: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: VH(40),
        '& > Button': {
            width: VW(533),
            height: VH(56),
            borderRadius: VW(4),
            fontSize: VW(16),
            fontWeight: 700,
            backgroundColor: "#ffffff",
            color: "#2195D3",
            border: `1px solid #2195D3`,
        }
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
    subtitleSign: {
        width: 5,
        height: 30,
        marginRight: 0,
        background: '#0A679A'
    },
    subtitle: {
        display: 'flex',
        height: 45,
        alignItems: 'flex-end',
        marginBottom: 5,
        fontWeight: 700
    }
});



function HelpDialog(props) {
    const {
        classes,
        open,
        onClose,
    } = props

    const [url, setUrl] = useState(null)
    const [page, setPage] = useState(0)
    const [modalWidth, setModalWidth] = useState(null);
    const modalRef = useRef(null);




    useEffect(() => {
        if (open) {
            ApiHelpFile().then(res => {
                let resData = res.data
                if (res.status === 200) {
                    setUrl(resData)
                } else {
                }
            }).catch(err => {

            })
        }
    }, [open])

    useEffect(() => {
        if (modalRef.current) {
            const resizeObserver = new ResizeObserver(() => {
                setModalWidth(modalRef.current.offsetWidth);
            });
            resizeObserver.observe(modalRef.current);

            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [open]);


    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(640)}
            closable={false}
            width="100vw"
            style={{ maxWidth: '100vw' }}
            bodyStyle={{ padding: '0' }}
        >
            <Box ref={modalRef} style={{ paddingBottom: 20 }}>
                <div className={classes.tableTitle}>
                    <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>【支援区分】</div>

                    <IconButton
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: VW(24),
                        }}
                    >
                        <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                    </IconButton>
                </div>
                {/* {url && */}
                <div >
                    <Document
                        file={url}
                        loading={""}
                        noData={""}
                        onLoadSuccess={(pageInfo) => {
                            setPage(pageInfo.numPages);
                        }}
                    // options={{ workerSrc: "/pdf.worker.min.js" }}
                    >
                        <Page width={modalWidth} pageNumber={page} />
                    </Document>
                </div>
                {/* } */}
                {/* <div className={classes.tableTitle}>
                <div style={{ fontWeight: 'bold', marginLeft: VW(8) }}>業務の種類</div>

                <IconButton
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: VW(24),
                    }}
                >
                    <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                </IconButton>
            </div>
            <div className={classes.subtitle}>
                <div className={classes.subtitleSign}></div>    
                <div style={{ fontWeight: 'bold', fontSize: VW(18), marginLeft: VW(8) }}>訪問業務以外</div>
            </div>
            <br />
            <div style={{ fontSize: VW(16), marginLeft: VW(8) }}>定例会、GIGAヘルプ、研修会、授業見学などを指します。</div>
            <div className={classes.buttonGroup}>
                <Button onClick={onClose}>閉じる</Button>
            </div> */}
            </Box>
        </Modal >
    )
}

HelpDialog.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(HelpDialog);