import React, { useEffect, useState, useRef } from "react";
import log from "../function/log";
import { withStyles } from "@mui/styles";
import { Modal, Button } from 'antd';
import dayjs from 'dayjs';
import utc from "dayjs/plugin/utc";
import { VW, VH } from "../function/sizeAdapter"
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import SupportView from "./SupportView";
import HelpDialog from "./HelpDialog";

dayjs.extend(utc)

const styles = (theme) => ({
    noneButton: {
        '& .ant-modal-footer': {
            display: 'none'
        }
    },
    buttonGroup: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center",
        '& > Button': {
            width: VW(97),
            height: VH(40),
            borderRadius: VW(4),
            fontSize: VW(12),
            fontWeight: 700,
            backgroundColor: "#ffffff",
            color: "#2195D3",
            border: `1px solid #2195D3`,
        }
    },
    buttonGroup1: {
        borderRadius: "4px",
        width: VW(240),
        height: VH(56),
        fontSize: VW(16),
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`
    },
    buttonGroup2: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: VH(24),
        marginBottom: VH(36),
        '& > Button': {
            width: VW(533),
            height: VH(56),
            borderRadius: VW(4),
            fontSize: VW(16),
            fontWeight: 700,
            backgroundColor: "#ffffff",
            color: "#2195D3",
            border: `1px solid #2195D3`,
        }
    },
    buttonGroup3: {
        color: '#FFF',
        backgroundColor: theme.palette.primary.main,
        marginLeft: VW(16)
    },
    tableTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: VW(20),
        marginBottom: VH(13)
    },
});


let supportItem = {
    "support_contents_id": null,
    "homon_jiseki_id": null,
    "gako_code": null,
    "sienkubun_code": null,
    "kyoka_code": null,
    "gakunen_code": null,
    "requester": null,
    "ict_kankyo_code1": null,
    "ict_kankyo_code2": null,
    "software_code1": null,
    "software_code2": null,
    "status": null,
    "support": null,
    "delete_flag": false,
    "expand": 0
}
function SupportDialog(props) {
    const {
        classes,
        open,
        onClose,
        visitData,
        setVisitData,
        index,
        item,
        updateVisitData,
        masterData,
        updateVisitSupportData
    } = props


    useEffect(() => {

    }, [])

    const [helpOpen, setHelpOpen] = useState(false)
    const supportRefs = useRef([]);
    const helpOnClose = () => {
        setHelpOpen(false)
    }

    const deleteSupportData = (index, supportIndex) => {
        // if (visitData?.homon_jiseki[index]?.supportList.length <= 1) {
        //     return
        // }

        visitData?.homon_jiseki[index]?.supportList?.splice(supportIndex, 1)
        log(visitData)
        setVisitData({ ...visitData })
    };
    const addSupportData = () => {
        let tempList = visitData.homon_jiseki
        // tempList[index].supportList.unshift(supportItem);
        tempList[index].supportList.push(supportItem)
        setVisitData(JSON.parse(JSON.stringify({ "homon_jiseki": tempList })))
        if (tempList[index].supportList.length > 1) {
            setTimeout(() => {
                const lastSupportRef = supportRefs.current[tempList[index].supportList.length - 1];
                if (lastSupportRef) {
                    lastSupportRef.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    });
                }
            }, 0);
        }

    };


    return (
        <Modal
            open={open}
            onCancel={onClose}
            className={classes.noneButton}
            centered
            width={VW(840)}
            closable={false}
        >
            <div className={classes.tableTitle} style={{ position: 'sticky', top: '0', zIndex: '1000', backgroundColor: "#ffffff" }}>
                <div style={{ fontWeight: 'bold', marginLeft: VW(8), flex: 1 }}>{visitData?.homon_jiseki[index].name}の「支援内容」入力</div>
                {/* <div style={{ cursor: "pointer", color: "#2195D3", fontWeight: 'bold', fontSize: 16, marginRight: "16px" }} onClick={addSupportData}
                    disabled={visitData.homon_jiseki[index].supportList.length >= 10 ? true : false}
                >支援内容の追加</div> */}
                <div disabled={visitData.homon_jiseki[index].supportList.length >= 10 ? true : false} className={classes.buttonGroup} style={{ marginRight: VW(8) }}>
                    <Button onClick={addSupportData} >支援内容の追加</Button>
                </div>

                <IconButton
                    onClick={onClose}
                    sx={{
                    }}
                >
                    <Close style={{ color: "#333333", width: VW(23.33), height: VW(23.33) }} />
                </IconButton>
            </div>
            {Object.keys(visitData) !== 0 && visitData?.homon_jiseki[index].supportList.map((supportItem, supportIndex) => (
                <div
                    ref={(el) => (supportRefs.current[supportIndex] = el)}
                    key={supportIndex}
                    style={{ scrollMargin: 55 }}
                >

                    <SupportView
                        key={supportIndex}
                        visitData={visitData}
                        setVisitData={setVisitData}
                        supportIndex={supportIndex}
                        index={index}
                        item={item}
                        supportItem={supportItem}
                        updateVisitData={updateVisitData}
                        masterData={masterData}
                        updateVisitSupportData={updateVisitSupportData}
                        deleteSupportData={deleteSupportData}
                        setHelpOpen={setHelpOpen}
                        helpOnClose={helpOnClose}

                    ></SupportView>
                </div>
            ))}

            <div className={classes.buttonGroup2} style={{ marginBottom: VH(5), paddingBottom: VH(5) }}>
                {/* <Button style={{ width: VW(240), height: VH(56), fontSize: VW(16), fontWeight: 'bold' }} onClick={onClose}>閉じる</Button> */}
            </div>
            <HelpDialog
                open={helpOpen}
                onClose={helpOnClose}
            />
        </Modal>

    )
}

SupportDialog.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(SupportDialog);