import React, { useEffect, useState, Fragment } from "react";
import {
    Button,
    TextField,
    IconButton
} from "@mui/material";
import log from "../componets/function/log";
import { useNavigate, useLocation } from "react-router-dom";
import { withStyles } from "@mui/styles";
import { routerNames } from '../wordsFile/wordsFile'
import { formatCheck, errorMessage, successMessage, localstorage } from "../wordsFile/wordsFile";
import Common_errorAlert from "../componets/common/Common_errorAlert";
import Common_successAlert from "../componets/common/Common_successAlert";
import { ApiTokenForgotPassword, ApiTokenConfirmForgotPassword, ApiMasterInfo, ApiMenuAuthorityInfo } from '../network/api'
import { message } from 'antd';
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { VW, VH } from "../componets/function/sizeAdapter"
import { ArrowBackIos } from "@mui/icons-material";
import { objectToString, stringToObejct } from "../componets/function/JsonUtil";
import { getSession, getUserPool, signInWithEmail } from "../network/UserPool";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import { confirmPassword, forgotPassword } from "../network/UserPool";
import { showLoading, hideLoading } from "../componets/common/loading";

const styles = (theme) => ({
    groundBlock: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
    },
    LoginView: {
        width: VW(680),
        backgroundColor: '#FFF',
        boxShadow: '0px 0.26vw 1.05vh 0.26vw #EAEFF3',
        textAlign: 'center',
        paddingTop: VH(40),
        marginTop: VH(30),
        position: 'absolute',
    },
    tittleIcon: {
        width: '100%',
        textAlign: 'center',
        fontSize: VW(24),
        fontWeight: 'bold'
    },
    annotateText: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: VH(24),
        '& > div': {
            width: VW(400),
            textAlign: 'left',
            fontSize: VW(16),
        }
    },
    label: {
        width: '100%',
        display: "flex",
        alignItems: 'flex-end',
        justifyContent: 'center',
        marginTop: VH(24)
    },
    text: {
        width: '100%',
        display: "flex",
        justifyContent: 'center',
        fontSize: VW(16),
    },
    loginButton: {
        backgroundColor: theme.palette.primary.main,
        marginTop: VH(40),
        fontSize: VW(16),
        fontWeight: 'bold',
        marginBottom: VH(40)
    },
    input: {
        height: VH(48),
        fontSize: VW(16),
    },
    inputContent: {
        padding: VH(14),
        fontSize: VW(16),
        fontSize: VW(16)
    },
    linkFont: {
        color: '#0052CC',
        marginTop: VH(12),
        fontSize: VW(16),
        '& > span': {
            cursor: 'pointer'
        }
    },
    sendAgainLink: {
        display: 'flex',
        justifyContent: 'center',
        '& > div': {
            width: VW(400),
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: VH(8),
        },
        '& > div > div': {
            color: theme.palette.primary.main,
            fontSize: VW(12),
            cursor: 'pointer',
        }
    },
    backLink: {
        fontSize: VW(12),
        color: theme.palette.primary.main,
        position: 'absolute',
        top: VH(12),
        left: VW(12),
        cursor: 'pointer'
    },
    errorAlert: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: VH(24)
    }
});

function ChangePassword(props) {
    const {
        classes,
    } = props

    const navigate = useNavigate()
    const location = useLocation();
    const { state } = location;
    const [email, setEmail] = useState(state)


    // success message
    const [successOpen, setSuccessOpen] = useState(false)
    const [successContent, setSuccessContent] = useState('')
    // error message
    const [errorOpen, setRrrorOpen] = useState(false)
    const [errorContent, setErrorContent] = useState('')



    // send code api
    const sendCodeAgain = () => {
        setRrrorOpen(false)
        showLoading()
        forgotPassword(state)
            .then(() => {
                hideLoading()
                setRrrorOpen(false)
                setSuccessContent(successMessage.codeSendSuccess)
                setSuccessOpen(true)
            })
            .catch((err) => {
                if (err.code === "LimitExceededException") {
                    setErrorContent(errorMessage.E00004)
                    setRrrorOpen(true)
                    hideLoading()
                } else {
                    setErrorContent(errorMessage.E00005)
                    setRrrorOpen(true)
                    hideLoading()
                }
            });
        // let param = {
        //     email: state
        // }
        // ApiTokenForgotPassword(param).then(res => {
        //     let resData = res.data
        //     if (res.status === 200) {
        //         log(resData)
        //         setRrrorOpen(false)
        //         setSuccessContent(successMessage.codeSendSuccess)
        //         setSuccessOpen(true)
        //     }
        // }).catch(err => {

        // })
    }



    // password display
    const [showPasswordA, setShowPasswordA] = useState(true);
    const handleClickShowPasswordA = () => setShowPasswordA((show) => !show);
    const handleMouseDownPasswordA = (event) => {
        event.preventDefault();
    };
    const [showPasswordB, setShowPasswordB] = useState(true);
    const handleClickShowPasswordB = () => setShowPasswordB((show) => !show);
    const handleMouseDownPasswordB = (event) => {
        event.preventDefault();
    };

    // change password api
    const [code, setCode] = useState('')
    const [newPasswordA, setNewPasswordA] = useState('')
    const [newPasswordB, setNewPasswordB] = useState('')

    const toChangePassword = () => {
        var a = newPasswordA.match(/[^\x00-\xff]/g)
        if (code === '') {
            setErrorContent(errorMessage.codeEmpty)
            setRrrorOpen(true)
            setSuccessOpen(false)
        } else if (newPasswordA === '') {
            setErrorContent(errorMessage.newPassEmpty)
            setRrrorOpen(true)
            setSuccessOpen(false)
        } else if (newPasswordB === '') {
            setErrorContent(errorMessage.newConfirmPassEmpty)
            setRrrorOpen(true)
        } else if (!formatCheck.Password.test(newPasswordA) || !formatCheck.Password.test(newPasswordB)) {
            setErrorContent(errorMessage.passwordFormatError)
            setRrrorOpen(true)
            setSuccessOpen(false)
        } else if (newPasswordA != newPasswordB) {
            setErrorContent(errorMessage.namePasswordDif)
            setRrrorOpen(true)
            setSuccessOpen(false)
        } else if (a != null) {
            setErrorContent(errorMessage.passwordelseError)
            setRrrorOpen(true)
            setSuccessOpen(false)
        } else {
            setRrrorOpen(false)
            showLoading()
            confirmPassword(state, code, newPasswordA)
                .then(() => {
                    hideLoading()
                    // navigate(routerNames.ChangeOK)
                    login()
                })
                .catch((err) => {
                    if (err.message.includes('Invalid verification code provided, please try again.')) {
                        setErrorContent(errorMessage.E00002)
                        setRrrorOpen(true)
                        hideLoading()
                    } else if (err.message.includes('Invalid session for the user.')) {
                        setErrorContent(errorMessage.E00003)
                        setRrrorOpen(true)
                        hideLoading()
                    } else {
                        setErrorContent(errorMessage.passwordFormatError)
                        setRrrorOpen(true)
                        hideLoading()
                    }
                });
            // let params = {
            //     email: state,
            //     password: newPasswordA,
            //     confirm_code: code
            // }
            // ApiTokenConfirmForgotPassword(params).then(res => {
            //     let resData = res.data
            //     if (res.status === 200) {
            //         log(resData)
            //         // navigate(routerNames.ChangeOK)
            //         login()
            //     } else {
            //         setSuccessOpen(false)
            //         setErrorContent(resData.detail)
            //         setRrrorOpen(true)
            //     }
            // }).catch(err => {

            // })
        }
    }

    const login = () => {
        setRrrorOpen(false)
        signInWithEmail(state, newPasswordA)
            .then((res) => {
                console.log(res)
                if (res.code == 'newPasswordRequired') {
                    navigate(routerNames.NewPassword, { state: { email: state } })
                } else {
                    let data = res.data
                    if (data.idToken.payload['custom:validity_from'] && data.idToken.payload['custom:validity_to']) {
                        if (dayjs() > dayjs(data.idToken.payload['custom:validity_from']) &&
                            dayjs() < dayjs(data.idToken.payload['custom:validity_to'])) {
                            localStorage.setItem(localstorage.access_token, data.accessToken.jwtToken)
                            localStorage.setItem(localstorage.refresh_token, data.refreshToken.token)
                            localStorage.setItem(localstorage.id_token, data.idToken.jwtToken)
                            localStorage.setItem(localstorage.authority_id1, data.idToken.payload['custom:authority1'])
                            localStorage.setItem(localstorage.authority_id2, data.idToken.payload['custom:authority2'])
                            localStorage.setItem(localstorage.authority_id3, data.idToken.payload['custom:authority3'])
                            localStorage.setItem(localstorage.authority_id4, data.idToken.payload['custom:authority4'])
                            localStorage.setItem(localstorage.authority_id5, data.idToken.payload['custom:authority5'])
                            localStorage.setItem(localstorage.preferred_username, data.idToken.payload['custom:preferred_username'])
                            localStorage.setItem(localstorage.email, data.idToken.payload.email)
                            localStorage.setItem(localstorage.username, data.idToken.payload.sub)
                            localStorage.setItem(localstorage.loginDate, dayjs())
                            getMasterInfo()
                        } else {
                            setErrorContent(errorMessage.E0003)
                            setRrrorOpen(true)
                        }
                    } else {
                        setErrorContent(errorMessage.E0003)
                        setRrrorOpen(true)
                    }

                }
            })
            .catch((err) => {
                console.log(err)
                if (err.message.includes('Incorrect username or password.')) {
                    setErrorContent(errorMessage.E0003)
                    setRrrorOpen(true)
                } else {
                    setErrorContent(err.message)
                    setRrrorOpen(true)
                }
            })
    }
    const getMasterInfo = () => {
        let param = { user_id: localStorage.getItem(localstorage.username) }
        ApiMasterInfo(param).then(res => {
            let resData = res.data
            log(res)
            if (res.status === 200) {
                var i = 1
                // var str = ""
                var jigyoKubunOptionList = []
                for (let index = 0; index < resData.master_info.gako_info.length; index++) {
                    if (resData.master_info.gako_info[index].homonJissekiFuragu === "1") {
                        jigyoKubunOptionList.push({ "value": i.toString(), "label": resData.master_info.gako_info[index].jigyoKubun })
                        i += 1
                    }
                }
                jigyoKubunOptionList = jigyoKubunOptionList.filter((item, index, array) =>
                    array.findIndex((i) => i.label === item.label) === index
                );
                jigyoKubunOptionList.map((item, index) => {
                    item.value = index.toString()
                })
                resData.master_info["jigyoKubunOptionList"] = jigyoKubunOptionList
                resData.master_info.sagyobasyo_info.push({ "no": "0", "homonsakiMeisho": "その他" })
                localStorage.setItem(localstorage.masterData, objectToString(resData.master_info))
                getMenuInfo()


            } else {
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const getMenuInfo = () => {
        ApiMenuAuthorityInfo().then(res => {
            let resData = res.data
            log(res)
            if (res.status === 200) {
                let menuList = []
                let menuData = {}
                // let manager = true
                for (let index = 0; index < resData?.menu_Info?.length; index++) {
                    // if (resData.menu_Info[index].biko == "訪問実績入力") {
                    //     manager = false
                    // }
                    let menuAuthority = {
                        name: resData.menu_Info[index].biko,
                        link: resData.menu_Info[index].url,
                        selectColor: '#053550',
                        iconColor: '#AAAAAA',
                    }
                    menuList.push(menuAuthority)
                }
                menuData["data"] = menuList
                localStorage.setItem(localstorage.menuItem, objectToString(menuData))
                navigate(menuData?.data[0]?.link)
                // if (manager) {
                //     navigate(routerNames.TerminalManage)
                // } else {
                //     navigate(routerNames.VisitRecordInput)
                // }

            }
        }).catch(err => {
            console.log(err)
        })
    };

    return (
        <div className={classes.groundBlock}>
            <div className={classes.LoginView}>
                <div className={classes.backLink} onClick={() => { window.history.back() }}><div style={{ display: 'flex', alignContent: "center", alignItems: "center" }}><ArrowBackIos style={{ width: VW(16), height: VW(16) }}></ArrowBackIos>前に戻る </div></div>
                <div className={classes.tittleIcon}>パスワード再設定</div>
                {(successOpen || errorOpen) && < div className={classes.errorAlert}>
                    < div style={{ width: VW(400) }}>
                        <Common_successAlert
                            open={successOpen}
                            setSuccessOpen={setSuccessOpen}
                            successContent={successContent}
                        />
                        <Common_errorAlert
                            open={errorOpen}
                            setRrrorOpen={setRrrorOpen}
                            errorContent={errorContent}
                        />
                    </div>
                </div>}
                <div className={classes.annotateText}>
                    <div>{state} に認証コードを送信しました。認証コードと新しいパスワードを入力してください。<br />{"※パスワードは8文字以上、英大文字、英小文字、数字、記号(!@#$%^&*()<>[]{}|_+-=)をそれぞれ最低1つずつ含める必要があります。"}</div>
                </div>
                <div className={classes.label}>
                    <div style={{ width: VW(400), textAlign: 'left', marginBottom: VH(8), fontSize: VW(14) }}>認証コード <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                </div>
                <div className={classes.text} >
                    <form autoComplete="off">
                        <TextField
                            sx={{ width: VW(400) }}
                            name="code"
                            placeholder="６桁の認証コードを入力してください"
                            onChange={(event) => {
                                setCode(event.target.value)
                            }}
                            value={code}
                            autoComplete="off"
                            onFocus={(event) => { event.target.type = "text" }}
                            InputProps={{
                                className: classes.input,
                                classes: {
                                    input: classes.inputContent
                                }

                            }}
                        />
                    </form>
                </div>
                <div className={classes.sendAgainLink}>
                    <div>
                        <div onClick={sendCodeAgain}>認証コードを再送信する</div>
                    </div>
                </div>
                <div className={classes.label}>
                    <div style={{ width: VW(400), textAlign: 'left', marginBottom: VH(8), fontSize: VW(14) }}>新しいパスワード <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                </div>
                <div className={classes.text} >
                    {/* <form autoComplete="off"> */}
                    <TextField
                        sx={{ width: VW(400) }}
                        type={showPasswordA ? 'password' : 'text'}
                        placeholder="パスワードを入力してください"
                        onChange={(event) => { setNewPasswordA(event.target.value) }}
                        value={newPasswordA}
                        // autoComplete='off'
                        InputProps={{
                            className: classes.input,
                            endAdornment: (
                                <IconButton
                                    onClick={handleClickShowPasswordA}
                                    onMouseDown={handleMouseDownPasswordA}
                                    edge="end"
                                >
                                    {showPasswordA ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            ),
                            classes: {
                                input: classes.inputContent
                            }
                        }}
                    />
                    {/* </form> */}
                </div>
                <div className={classes.label}>
                    <div style={{ width: VW(400), textAlign: 'left', marginBottom: VH(8), fontSize: VW(14) }}>新しいパスワード（確認用） <span style={{ color: 'red', fontWeight: 'bold' }}>*</span></div>
                </div>
                <div className={classes.text} >
                    {/* <form autoComplete="off"> */}
                    <TextField
                        type="text"
                        name="username"
                        autoComplete="username"
                        sx={{ position: "absolute", zIndex: -100000 }}
                        value={email}

                    />

                    <TextField
                        sx={{ width: VW(400) }}
                        type={showPasswordB ? 'password' : 'text'}
                        placeholder="パスワードを入力してください"
                        onChange={(event) => { setNewPasswordB(event.target.value) }}
                        value={newPasswordB}
                        // autoComplete='off'
                        InputProps={{
                            className: classes.input,
                            endAdornment: (
                                <IconButton
                                    onClick={handleClickShowPasswordB}
                                    onMouseDown={handleMouseDownPasswordB}
                                    edge="end"
                                >
                                    {showPasswordB ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            ),
                            classes: {
                                input: classes.inputContent
                            }
                        }}
                    />
                    {/* </form> */}
                </div>
                <Button
                    className={classes.loginButton}
                    variant="contained"
                    sx={{ width: VW(400), height: VH(48) }}
                    onClick={toChangePassword}
                >パスワード再設定
                </Button>
            </div>
        </div >
    )
}

ChangePassword.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(ChangePassword);