import React, { useEffect, useState, Fragment } from "react";
import {
    Box
} from "@mui/material";
import { withStyles } from "@mui/styles";
import HeaderBar from "../componets/bar/HeaderBar";
import LeftMenu from "../componets/bar/LeftMenu";
import MainRouters from "../routes/MainRouters";
import { useLocation, useNavigate } from 'react-router-dom';
import { VW, VH } from "../componets/function/sizeAdapter"
import { localstorage, routerNames } from "../wordsFile/wordsFile";
import { objectToString, stringToObejct } from "../componets/function/JsonUtil";
import log from "../componets/function/log";
import WindowCloseDialog from "../componets/dialog/WindowCloseDialog";
import OffDialog1 from "../componets/dialog/OffDialog1";
import OffDialog2 from "../componets/dialog/OffDialog2";
import OffDialog3 from "../componets/dialog/OffDialog3";
import { getUserPool } from "../network/UserPool";
import dayjs from "dayjs";

const styles = (theme) => ({
    Box: {
        width: '100%',
        height: '100vh'
    },
    headerBar: {
        width: '100%',
        height: VH(70),
    },
    bodyMain: {
        width: '100%',
        height: VH(650),
        display: 'flex',
        backgroundColor: "#F4F5F9"
    },
    menu: {
        height: '100%',
        width: VW(240),
        borderRight: `1px solid ${theme.palette.themeBorder.main}`,
        transition: 'all 0.1s',
        overflow: 'hidden',
        backgroundColor: "#053550"
    },
    content: {
        height: '100%',
        transition: 'all 0.1s',
        overflowY: 'scroll',
        overflowY: 'overlay'
    },
});

function Home(props) {
    const {
        classes,
    } = props

    const navigate = useNavigate()
    const [visitDataCompared, setVisitDataCompared] = useState({})
    const [visitData, setVisitData] = useState({})
    const [selectFlag, setSelectFlag] = useState('/home/dashBoard')
    const [isUpdate, setIsUpdate] = useState(false)
    const [masterData, setMasterData] = useState(stringToObejct(localStorage.getItem(localstorage.masterData)))
    const [refreshVisit, setRefreshVisit] = useState(false)
    const [vacationSelect, setVacationSelect] = useState('')



    useEffect(() => {
        if (!localStorage.getItem('access_token')) {
            navigate('/')
        }
    }, [])

    const handlePopstate = (event) => {
        alert('ブラウザバックを使わないでください。');
        event.preventDefault();

        windowCloseOnOpen()
    };
    // useEffect(() => {
    //     // ブラウザバックを禁止する
    //     window.history.pushState(null, null, window.location.href);
    //     window.addEventListener('popstate', (e) => {
    //         // alert('ブラウザバックを使わないでください。');
    //         window.history.go(1);
    //     });
    // })
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            const loginDate = dayjs(localStorage.getItem(localstorage.loginDate))
            if (selectFlag == routerNames.VisitRecordInput && JSON.stringify(visitDataCompared) != JSON.stringify(visitData)) {

                const message = "本当に終了してもよろしいですか?";
                event.returnValue = message;
                return message;
            }

            // event.preventDefault();
            // return (event.returnValue = '本当に終了してもよろしいですか?')
        };
        const handleVisibilityChange = (event) => {
            const loginDate = dayjs(localStorage.getItem(localstorage.loginDate))
            if (selectFlag == routerNames.VisitRecordInput && JSON.stringify(visitDataCompared) != JSON.stringify(visitData)) {
                const message = "本当に終了してもよろしいですか?";
                event.returnValue = message;
                return message;
            }
        };
        const handlePageHide = (event) => {
            const loginDate = dayjs(localStorage.getItem(localstorage.loginDate))
            if (selectFlag == routerNames.VisitRecordInput && JSON.stringify(visitDataCompared) != JSON.stringify(visitData)) {
                const message = "本当に終了してもよろしいですか?";
                event.returnValue = message;
                return message;
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('pagehide', handlePageHide);
        // window.addEventListener('onunload', handlePopstate);
        // window.addEventListener('popstate', handlePopstate);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('pagehide', handlePageHide);
            // window.removeEventListener('onunload', handlePopstate);
            // window.removeEventListener('popstate', handlePopstate);
        };

    }, [selectFlag, visitDataCompared, visitData]);

    useEffect(() => {
        // console.log(visitData)
        // console.log(vacationSelect)
        // console.log(Object.keys(visitData))
        // console.log(Object.keys(visitData).length > 0 ? visitData?.homon_jiseki[0]?.kyuka_kubun || "" : vacationSelect)
        // if (Object.keys(visitData) != 0 && selectFlag == routerNames.VisitRecordInput) {
        //     localStorage.setItem(localstorage.visitData, objectToString(visitData))
        // }
    }, [visitData])

    const [windowCloseOpen, setWindowCloseOpen] = useState(false)
    const [windowNextRoute, setWindowNextRoute] = useState(null)
    const windowCloseOnClose = () => {
        setWindowCloseOpen(false)
    }
    const windowCloseOnOpen = () => {
        setWindowCloseOpen(true)
    }
    const windowCloseConfirm = () => {
        setWindowCloseOpen(false)
        if (windowNextRoute == "logout") {
            localStorage.removeItem(localstorage.access_token)
            localStorage.removeItem(localstorage.refresh_token)
            localStorage.removeItem(localstorage.id_token)
            localStorage.removeItem(localstorage.authority_id1)
            localStorage.removeItem(localstorage.authority_id2)
            localStorage.removeItem(localstorage.authority_id3)
            localStorage.removeItem(localstorage.authority_id4)
            localStorage.removeItem(localstorage.authority_id5)
            localStorage.removeItem(localstorage.preferred_username)
            localStorage.removeItem(localstorage.email)
            localStorage.removeItem(localstorage.username)
            const userPool = getUserPool();
            const user = userPool.getCurrentUser();
            if (user) {
                user.signOut();
                navigate('/')
            } else {
                navigate('/')
            }
        } else if (windowNextRoute == "翌月訪問予定入力") {
            const menuItems = stringToObejct(localStorage.getItem(localstorage.menuItem)).data
            let link = ""
            menuItems.map(element => {
                if (element.name === "翌月訪問予定入力") {
                    link = element.link
                }
            })
            window.open(link, '_blank');

        } else {
            navigate(windowNextRoute)
        }
    }

    const [offDialog1Open, setOffDialog1Open] = useState(false)
    const [offDialog1SelectValue, setOffDialog1SelectValue] = useState("")
    const offDialog1CloseOnClose = () => {
        setOffDialog1Open(false)
    }
    const offDialog1CloseOnOpen = () => {
        setOffDialog1Open(true)
    }
    const offDialog1Confirm = () => {
        if (visitData?.homon_jiseki?.length > 0) {
            for (let i = 0; i < visitData?.homon_jiseki?.length; i++) {
                visitData.homon_jiseki[i].kyuka_kubun = offDialog1SelectValue
                visitData.homon_jiseki[i].supportList = []
                visitData.homon_jiseki[i].kotsukikanList = []
                visitData.homon_jiseki[i].teiji_jikan = null
                visitData.homon_jiseki[i].jigyo_kubun = null
                visitData.homon_jiseki[i].homon_saki = null
                visitData.homon_jiseki[i].sagyo_basho = null
                visitData.homon_jiseki[i].sagyo_basho_sonota = null
                visitData.homon_jiseki[i].sagyo_naiyo = null
                visitData.homon_jiseki[i].jikangai = null
                visitData.homon_jiseki[i].gyomu_shurui = null
                visitData.homon_jiseki[i].homon_shuppatsu = null
                visitData.homon_jiseki[i].homon_tochaku = null
            }
            setVisitData({ ...visitData })
        }
        setVacationSelect(offDialog1SelectValue)
        setOffDialog1Open(false)
    }

    const [offDialog2Open, setOffDialog2Open] = useState(false)
    const offDialog2CloseOnClose = () => {
        setOffDialog2Open(false)
    }
    const offDialog2CloseOnOpen = () => {
        setOffDialog2Open(true)
    }

    const [offDialog3Open, setOffDialog3Open] = useState(false)
    const [offDialog3SelectIndex, setOffDialog3SelectIndex] = useState()
    const offDialog3CloseOnClose = () => {
        setOffDialog3Open(false)
    }
    const offDialog3CloseOnOpen = () => {
        setOffDialog3Open(true)
    }
    const offDialog3Confirm = () => {
        visitData.homon_jiseki[offDialog3SelectIndex].gyomu_shurui = "2"
        visitData.homon_jiseki[offDialog3SelectIndex].homon_saki = ""
        visitData.homon_jiseki[offDialog3SelectIndex].supportList = []
        if (visitData.homon_jiseki[offDialog3SelectIndex].sagyo_basho == "3" || visitData.homon_jiseki[offDialog3SelectIndex].sagyo_basho_sonota == "GIGAヘルプデスク") {

        } else {
            visitData.homon_jiseki[offDialog3SelectIndex].teiji_jikan = ""
        }
        setVisitData({ ...visitData })
        setOffDialog3Open(false)
    }


    const updateVisitData = (item, index, flag) => {

        if (flag === "kyuka_kubun") {
            if (item == "1" || item == "4") {
                if (visitData?.homon_jiseki?.length === 2) {
                    offDialog2CloseOnOpen()
                } else {
                    setOffDialog1SelectValue(item)
                    offDialog1CloseOnOpen()
                }

            } else if (item == "2" || item == "3" || item == "5" || item == "6") {
                if (visitData?.homon_jiseki?.length === 2) {
                    offDialog2CloseOnOpen()
                } else {
                    if (visitData?.homon_jiseki?.length > 0) {
                        for (let i = 0; i < visitData?.homon_jiseki?.length; i++) {
                            visitData.homon_jiseki[i][flag] = item
                            // visitData.homon_jiseki[i].teiji_jikan = ""
                            if (!visitData.homon_jiseki[i].gyomu_shurui) {
                                visitData.homon_jiseki[i].gyomu_shurui = "1"
                            }
                        }
                    }
                    setVacationSelect(item)

                }
            } else {
                if (visitData?.homon_jiseki?.length === 2) {
                    visitData.homon_jiseki[0][flag] = item
                    visitData.homon_jiseki[1][flag] = item
                    setVacationSelect(item)
                    if (!visitData.homon_jiseki[0].gyomu_shurui) {
                        visitData.homon_jiseki[0].gyomu_shurui = "1"
                    }
                    if (!visitData.homon_jiseki[1].gyomu_shurui) {
                        visitData.homon_jiseki[1].gyomu_shurui = "1"
                    }
                } else {
                    if (visitData?.homon_jiseki?.length > 0) {
                        visitData.homon_jiseki[0][flag] = item
                        if (!visitData.homon_jiseki[0].gyomu_shurui) {
                            visitData.homon_jiseki[0].gyomu_shurui = "1"
                        }
                    }
                    setVacationSelect(item)
                }
            }

        } else if (flag === "jigyo_kubun") {
            visitData.homon_jiseki[index][flag] = item
            // visitData.homon_jiseki[index].sagyo_basho = ""
            // visitData.homon_jiseki[index].homon_saki = ""
        } else if (flag === "homon_saki") {
            visitData.homon_jiseki[index][flag] = item
            var jigyo_kubun = ""
            masterData.gako_info.map((map, i) => {
                if (map.no === item) {
                    jigyo_kubun = map.jigyoKubun
                }
            })
            masterData.jigyoKubunOptionList.map((map) => {
                if (map.label === jigyo_kubun) {
                    visitData.homon_jiseki[index].jigyo_kubun = map.value
                }
            })
            for (let i = 0; i < visitData?.homon_jiseki[index]?.supportList.length; i++) {
                visitData.homon_jiseki[index].supportList[i].gako_code = item
            }
        } else if (flag === "sagyo_basho") {
            visitData.homon_jiseki[index][flag] = item
            visitData.homon_jiseki[index].sagyo_basho_sonota = ""
            var sagyo_basholabel = ""
            masterData.sagyobasyo_info.map((row) => {
                if (row.no == item) {
                    sagyo_basholabel = row.homonsakiMeisho
                }
            })
            visitData.homon_jiseki[index].homon_tochaku = sagyo_basholabel
            if (index == 1) {
                let temp = []
                visitData?.homon_jiseki[0]?.kotsukikanList.map((row) => {
                    if (row.ofuku == 2) {
                        temp.push(row)
                    }
                })
                if (temp.length > 0) {
                    visitData.homon_jiseki[1].homon_shuppatsu = "自宅"
                } else {
                    visitData.homon_jiseki[1].homon_shuppatsu = visitData.homon_jiseki[0].homon_tochaku
                }
            } else {
                visitData.homon_jiseki[0].homon_shuppatsu = "自宅"
            }
            if (item == "1") {
                visitData.homon_jiseki[index].kotsukikanList = []
            }
        } else if (flag === "sagyo_basho_sonota") {
            visitData.homon_jiseki[index][flag] = item
            visitData.homon_jiseki[index].homon_tochaku = item
            if (index == 1) {
                let temp = []
                visitData?.homon_jiseki[0]?.kotsukikanList.map((row) => {
                    if (row.ofuku == 2) {
                        temp.push(row)
                    }
                })
                if (temp.length > 0) {
                    visitData.homon_jiseki[1].homon_shuppatsu = "自宅"
                } else {
                    visitData.homon_jiseki[1].homon_shuppatsu = visitData.homon_jiseki[0].homon_tochaku
                }
            } else {
                visitData.homon_jiseki[0].homon_shuppatsu = "自宅"
            }
            // visitData.homon_jiseki[index].homon_saki = ""
            // visitData.homon_jiseki[index].jigyo_kubun = "0"
            if (item == "自宅") {
                visitData.homon_jiseki[index].kotsukikanList = []
            }
        } else if (flag === "homon_tochaku" && index === 0) {
            visitData.homon_jiseki[index][flag] = item
            if (visitData?.homon_jiseki?.length >= 2) {
                if (item === "自宅") {
                    visitData.homon_jiseki[1].homon_shuppatsu = "自宅"
                } else {
                    visitData.homon_jiseki[1].homon_shuppatsu = "訪問先1"
                }
            }

        } else if (flag === "gyomu_shurui") {
            if (item == "2") {
                if (visitData.homon_jiseki[index].homon_saki || visitData.homon_jiseki[index].teiji_jikan || visitData.homon_jiseki[index].supportList.length > 0) {
                    setOffDialog3SelectIndex(index)
                    offDialog3CloseOnOpen()
                } else {
                    visitData.homon_jiseki[index][flag] = item
                }

            } else {
                visitData.homon_jiseki[index][flag] = item
            }
        } else {
            visitData.homon_jiseki[index][flag] = item
        }
        for (let i = 0; i < visitData?.homon_jiseki?.length; i++) {
            if (visitData.homon_jiseki[i].kyuka_kubun == "1" || visitData.homon_jiseki[i].kyuka_kubun == "4" || visitData.homon_jiseki[i].gyomu_shurui == "2" &&
                visitData.homon_jiseki[i].sagyo_basho != "3" &&
                visitData.homon_jiseki[i].sagyo_basho_sonota != "GIGAヘルプデスク") {
                visitData.homon_jiseki[i].teiji_jikan = null
            }
        }
        setVisitData({ ...visitData })
        log(visitData)
    }
    const updateVisitSupportData = (item, index, suportIndex, flag) => {
        visitData.homon_jiseki[index].supportList[suportIndex][flag] = item
        setVisitData({ ...visitData })
        log(visitData)
    }
    const updateVisitRouteData = (item, index, routeIndex, flag) => {
        visitData.homon_jiseki[index].kotsukikanList[routeIndex][flag] = item
        setVisitData({ ...visitData })
        log(visitData)
    }
    // open or close menu
    const [menuFlag, setMenuFlag] = useState(true)
    const changeMenuStatus = () => {
        setMenuFlag(!menuFlag)
    }
    return (
        <Box className={classes.Box}>
            <Box className={classes.headerBar}>
                <HeaderBar
                    selectFlag={selectFlag}
                    changeMenuStatus={changeMenuStatus}
                    menuFlag={menuFlag}
                    setWindowCloseOpen={setWindowCloseOpen}
                    windowNextRoute={windowNextRoute}
                    setWindowNextRoute={setWindowNextRoute}
                    visitDataCompared={visitDataCompared}
                    setVisitDataCompared={setVisitDataCompared}
                    visitData={visitData}
                    setVisitData={setVisitData}
                />
            </Box>
            <Box className={classes.bodyMain}>
                <Box className={classes.menu} style={{ width: menuFlag ? VW(240) : '0' }}>
                    <LeftMenu
                        selectFlag={selectFlag}
                        refreshVisit={refreshVisit}
                        setRefreshVisit={setRefreshVisit}
                        windowCloseOpen={windowCloseOpen}
                        setWindowCloseOpen={setWindowCloseOpen}
                        setWindowNextRoute={setWindowNextRoute}
                        visitDataCompared={visitDataCompared}
                        setVisitDataCompared={setVisitDataCompared}
                        visitData={visitData}
                        setVisitData={setVisitData}
                    />
                </Box>
                <Box className={classes.content} id='father_Block' style={{ width: menuFlag ? VW(893) : '100%' }}>
                    <MainRouters
                        setSelectFlag={setSelectFlag}
                        visitData={visitData}
                        setVisitData={setVisitData}
                        isUpdate={isUpdate}
                        setIsUpdate={setIsUpdate}
                        updateVisitData={updateVisitData}
                        masterData={masterData}
                        updateVisitSupportData={updateVisitSupportData}
                        updateVisitRouteData={updateVisitRouteData}
                        refreshVisit={refreshVisit}
                        setRefreshVisit={setRefreshVisit}
                        visitDataCompared={visitDataCompared}
                        setVisitDataCompared={setVisitDataCompared}
                        menuFlag={menuFlag}
                        vacationSelect={vacationSelect}
                        setVacationSelect={setVacationSelect}

                    />
                </Box>
            </Box>

            <WindowCloseDialog
                open={windowCloseOpen}
                onClose={windowCloseOnClose}
                confirm={windowCloseConfirm}
            />
            <OffDialog1
                open={offDialog1Open}
                onClose={offDialog1CloseOnClose}
                offDialog1Confirm={offDialog1Confirm}
            />
            <OffDialog2
                open={offDialog2Open}
                onClose={offDialog2CloseOnClose}
            />
            <OffDialog3
                open={offDialog3Open}
                onClose={offDialog3CloseOnClose}
                offDialog3Confirm={offDialog3Confirm}
            />

        </Box>
    )
}

Home.defaultProps = {

}

export default withStyles(styles, { withTheme: true })(Home);